import { Box, Grid, SxProps, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import colors from '../../data/colors';
import { Card as CardType } from '../../types/card.types';
import Card from './Card';

export interface CardsSectionProps {
  title: string;
  cards: CardType[];
  cardSectionSx?: CardSectionSx;
  overlay?: 'all' | 'cards';
}

interface CardSectionSx {
  root?: SxProps;
  title?: SxProps;
  cardsContainer?: SxProps;
}

const overlayStyle: SxProps = {
  background: `transparent radial-gradient(closest-side at 66% 59%, ${colors.teal.regular} 0%, ${colors.skyblue.dark} 66%, ${colors.skyblue.regular} 100%) 0% 0% no-repeat padding-box`,
  opacity: 0.14,
  filter: 'blur(50px)',
  position: 'absolute',
  inset: 0,
};

const titleStyle: SxProps = { textAlign: 'center', mb: { xs: '2rem', sm: '2.5rem', md: '3rem' } };

const gridContainer: SxProps = { '&>.MuiGrid-item': { pl: { xs: 0, sm: 2 } } };

const gridItemContainer: SxProps = { pb: { xs: '0.25rem', sm: '5rem' } };

const section: SxProps = {
  maxWidth: '1200px',
  marginInline: 'auto',
  width: '80%',
};

const CardsSection = ({ title, cards, cardSectionSx, overlay = 'cards' }: CardsSectionProps) => {
  const { t } = useTranslation();
  const overlayAll: boolean = overlay === 'all';

  return (
    <Box component="section" position="relative" sx={cardSectionSx?.root}>
      {overlayAll && <Box sx={overlayStyle} />}
      <Typography
        variant="h2"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t(title)) }}
        sx={{ ...section, ...titleStyle, ...cardSectionSx?.title }}
        className="heading-primary"
      />
      <Box position="relative" sx={cardSectionSx?.cardsContainer}>
        {!overlayAll && <Box sx={overlayStyle} />}
        <Grid container spacing={2} position="relative" sx={{ ...section, ...gridContainer }}>
          {cards.map((card: CardType) => (
            <Grid key={card.title} item xs={12} sm={6} md={3} sx={gridItemContainer}>
              <Card {...card} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default CardsSection;
