import { Box, Grid, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import DOMPurify from 'dompurify';
import { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Location, useLocation } from 'react-router-dom';
import RocketIcon from '../../assets/img/rocket@2x.png';
import { ReactComponent as ArrowIcon } from '../../assets/svg/complete-arrow.svg';
import ListItems from '../../components/common/ListItems/ListItems';
import EbookForm from '../../components/EbookForm';
import EbookHeader from '../../components/EbookHeader';
import { PlexoUrl } from '../../data/urls';
import i18n from '../../i18n/config';

function EBook() {
  const { t } = useTranslation();
  const location: Location = useLocation();
  const theme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('md'));
  const year: number = new Date().getFullYear();
  const isAd: boolean = location.pathname.includes('ads');

  useLayoutEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    if (!html.classList.contains('ebook-ad')) html.classList.add('ebook-ad');

    return () => html.classList.remove('ebook-ad');
  }, []);

  useEffect(() => {
    localStorage.removeItem('localLanguage');
    if (isAd) i18n.changeLanguage('es');
  }, [isAd]);

  return (
    <Box id="ebook">
      <EbookHeader />
      <Grid container className="ebook-ad-container">
        <Grid item xs={12} md={9} className="text-column">
          <Typography className="small-title">{t('ebook-ad.ads-title')}</Typography>
          <Typography
            variant="h1"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('ebook-ad.title')) }}
          />
          {isMobile && (
            <a href="#text-items-mobile" className="button-container">
              <ArrowIcon />
            </a>
          )}
          {!isMobile && (
            <Box className="text-items">
              <Typography className="text">{t('ebook-ad.text')}</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <ListItems
                  texts={['ebook-ad.item-confidence', 'ebook-ad.item-conversion', 'ebook-ad.item-security']}
                  sx={{ display: 'flex', flexDirection: 'column' }}
                />
                <ListItems
                  texts={['ebook-ad.item-quality', 'ebook-ad.item-user']}
                  sx={{ display: 'flex', flexDirection: 'column' }}
                />
              </Box>
            </Box>
          )}
          {!isMobile && <Typography className="copyright-container">© {year} Plexo</Typography>}
        </Grid>
        <Grid item xs={12} md={3} className="form-column">
          <EbookForm />
          <img src={RocketIcon} alt="rocket" className="rocket-icon" />
        </Grid>
        {isMobile && (
          <>
            <Box id="text-items-mobile">
              <Box className="overlay" />
              <Typography className="text">{t('ebook-ad.text')}</Typography>
              <ListItems
                texts={[
                  'ebook-ad.item-confidence',
                  'ebook-ad.item-conversion',
                  'ebook-ad.item-security',
                  'ebook-ad.item-quality',
                  'ebook-ad.item-user',
                ]}
              />
              <a
                href={PlexoUrl.CALENDLY}
                className="plexo-button secondary-button"
                target="_blank"
                rel="noreferrer"
              >
                {t('ebook-ad.contact-button')}
              </a>
            </Box>
            <Typography className="copyright-container">© {year} Plexo</Typography>
          </>
        )}
      </Grid>
    </Box>
  );
}

export default EBook;
