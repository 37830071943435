import { Box } from '@mui/material';
import TinySlider, { TinySliderSettings } from 'tiny-slider-react';
import { Logo } from '../../types/logos.types';

export interface LogoSliderProps {
  logos: Logo[];
  settings?: TinySliderSettings;
}

const LogoSlider = ({ logos, settings }: LogoSliderProps) => {
  const sliderSettings: TinySliderSettings = {
    nav: false,
    items: 5,
    gutter: 10,
    controls: false,
    autoplay: true,
    autoplayButtonOutput: false,
    ...settings,
  };

  return (
    <Box>
      <TinySlider settings={sliderSettings}>
        {logos.map((logo: Logo) => (
          <img src={logo.img} alt={logo.title} key={logo.title} />
        ))}
      </TinySlider>
    </Box>
  );
};

export default LogoSlider;
