import { Box, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { TFunction, UseTranslationResponse, useTranslation } from 'react-i18next';
import ImageHeroBanner from '../../assets/img/img-banner-ggcc@2x.png';
import ImageBanner1Mobile from '../../assets/img/img-ggcc-1-xs@2x.png';
import ImageBanner1Desktop from '../../assets/img/img-ggcc-1@2x.png';
import ImageBanner2 from '../../assets/img/img-ggcc-2@2x.png';
import { ReactComponent as PayPuntaLogo } from '../../assets/svg/paypunta-color.svg';
import Banner, { BannerProps } from '../../components/common/Banner';
import BannerImageItems from '../../components/common/BannerImageItems';
import Seo from '../../components/common/Seo';
import SuccessCase from '../../components/common/SuccessCase';
import { ViewBannerProps } from '../../components/common/ViewBanner';
import ProductLayout from '../../components/products/ProductLayout';
import { PlexoUrl } from '../../data/urls';

const plexoPropTech: string = 'px-proptech';
const viewBanner: string = `${plexoPropTech}.viewbanner`;
const banner1: string = `${plexoPropTech}.banner-1`;
const banner2: string = `${plexoPropTech}.banner-2`;
const contact: string = `${plexoPropTech}.contact`;
const successCase: string = `${plexoPropTech}.success-case`;

const viewBannerImage: SxProps = {
  width: { xs: '210%', md: '230%' },
  mb: { md: '-26rem' },
  position: 'relative',
  top: { xs: '-9rem', md: '-10rem' },
  left: { xs: '3rem', md: '9rem' },
};

const banner1ImgContainerMargins: SystemStyleObject<Theme> = {
  mt: '2.2rem',
  mb: '7rem',
};

const banner1ImgDesktopStyle: SxProps = {
  width: '140%',
  position: 'relative',
  top: '3%',
  left: '-38%',
};

const banner1ImgMobileStyle: SxProps = {
  width: { xs: '240%', sm: '200%' },
  mt: 'clamp(-37rem, -122%, -14rem)',
  mb: '-8.5rem',
};

const item3MobileStyle: SystemStyleObject<Theme> = {
  width: { sm: '60%' },
  '& .MuiChip-label': { whiteSpace: 'normal' },
};

const item1Style: SystemStyleObject<Theme> = {
  minWidth: { md: '55%', lg: '30%' },
  top: { md: 'clamp(11rem, 37%, 19rem)' },
  right: { md: 'clamp(11rem, 56%, 21rem)' },
};

const item2Style: SystemStyleObject<Theme> = {
  minWidth: { md: '55%', lg: '42%' },
  top: { md: 'clamp(15rem, 47%, 24rem)' },
  right: { md: 'clamp(15rem, 66%, 28rem)' },
};

const item3Style: SystemStyleObject<Theme> = {
  minWidth: { md: '65%', lg: '50%' },
  top: { md: 'clamp(19rem, 60%, 32rem)' },
  right: { md: 'clamp(11rem, 52%, 30rem)' },
  '& .MuiChip-label': { whiteSpace: 'normal' },
};

const banner2ImgStyle: SxProps = {
  width: { xs: '200%', sm: '175%', md: '150%' },
  mb: { xs: '-9rem', lg: 'unset' },
  mt: { xs: '-6rem', md: 'unset' },
  ml: { xs: '2.2rem', lg: '8rem' },
};

const viewBannerProps: ViewBannerProps = {
  breadcrumbs: { goToProducts: true, current: `${viewBanner}.breadcrumb-current` },
  title: `${viewBanner}.title`,
  paragraph: `${viewBanner}.text`,
  buttonPrimary: {
    to: '/contacto',
    content: `${viewBanner}.button-primary`,
    sx: { mb: { xs: '-1rem', md: '1.55rem' } },
  },
  arrowIconTo: '#banner-1',
  image: {
    src: ImageHeroBanner,
    alt: `${viewBanner}.img-alt`,
    sx: viewBannerImage,
  },
  smallTitle: true,
  viewBannerSx: {
    root: { mb: { xs: '-18rem', md: 'unset' } },
    secondContainer: { display: 'flex', justifyContent: 'center' },
  },
};

const layoutReverseBannerProps = (isMobile: boolean): BannerProps => ({
  title: `${banner1}.title`,
  text: `${banner1}.text`,
  imageContainerContent: (
    <BannerImageItems
      items={[
        { itemText: `${banner1}.item-1`, itemCustomStyle: isMobile ? null : item1Style },
        { itemText: `${banner1}.item-2`, itemCustomStyle: isMobile ? null : item2Style },
        { itemText: `${banner1}.item-3`, itemCustomStyle: isMobile ? item3MobileStyle : item3Style },
      ]}
      imageDesktopSrc={ImageBanner1Desktop}
      imageMobileSrc={ImageBanner1Mobile}
      imageAlt={`${banner2}.img-alt`}
      bannerImgDesktopStyle={banner1ImgDesktopStyle}
      bannerImgContainerMargins={banner1ImgContainerMargins}
      bannerImgMobileStyle={banner1ImgMobileStyle}
    />
  ),
  button: { to: '/contacto', content: 'button-start', sx: { mt: { xs: '1rem' } } },
  id: 'banner-1',
  bannerSx: {
    wrapper: { mb: { md: '-14rem' } },
    textContainer: {
      pl: { md: '4.4rem' },
      '& h2, p': { maxWidth: { md: '79%' } },
    },
  },
});

const bannerProps = (t: TFunction<'translation', undefined>): BannerProps => ({
  title: `${banner2}.title`,
  text: `${banner2}.text`,
  imageContainerContent: (
    <Box component="img" src={ImageBanner2} sx={banner2ImgStyle} alt={t(`${banner2}.img-alt`)} />
  ),
  button: {
    to: '/contacto',
    content: `${banner2}.button`,
    alignLeftMobile: true,
    sx: { mt: { md: '1.25rem' }, borderRadius: { xs: '3rem' } },
  },
  bannerSx: {
    wrapper: { mb: { xs: '6rem', md: '-2rem' } },
  },
});

function PropTech() {
  const theme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('md'));
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  return (
    <>
      <Seo meta={`${plexoPropTech}.meta`} />
      <ProductLayout
        viewBannerProps={viewBannerProps}
        reverseBannerProps={layoutReverseBannerProps(isMobile)}
        contactProps={{
          title: `${contact}.title`,
          textContainerCustom: { width: { xs: '65%', md: '50%' }, padding: { xs: '6% 9%', md: '6%' } },
        }}
      >
        <Banner {...bannerProps(t)} />
        <SuccessCase
          logo={<PayPuntaLogo />}
          title={`${successCase}.title`}
          text1={`${successCase}.text-1`}
          text2={`${successCase}.text-2`}
          linkText={`${successCase}.link-text`}
          linkUrl={PlexoUrl.PX_PAYPUNTA}
        />
      </ProductLayout>
    </>
  );
}

export default PropTech;
