import { Box, Step, StepConnector, StepLabel, Stepper, SxProps, Theme, Typography } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { useTranslation } from 'react-i18next';
import colors from '../../data/colors';

const titleStyle: SxProps = {
  color: colors.teal.light,
  textAlign: 'center',
  mb: { xs: '2.375rem', md: '4.75rem' },
};

const stepper: SxProps = { mx: 'auto' };

const connector: SxProps = {
  mx: { xs: '26px', md: 'auto' },
  '&>.MuiStepConnector-line': {
    borderLeftWidth: '5px',
    borderColor: 'primary.main',
    minHeight: { xs: '75px', md: '104px' },
  },
};

const fixedSize: SxProps = { xs: '56px', md: '70px' };

const stepLabelStyle = (theme: Theme): SystemStyleObject<Theme> => ({
  height: fixedSize,
  justifyItems: 'center',
  gap: { xs: '1.25rem', md: '4rem' },
  display: { md: 'grid' },
  gridTemplateColumns: { md: '1fr 70px 1fr' },
  py: 0,
  '& .MuiStepLabel-iconContainer': {
    gridColumnStart: 2,
    gridColumnEnd: 3,
    pr: 0,
    justifyContent: 'center',
    '&>svg': {
      color: 'common.white',
      border: `5px solid ${theme.palette.primary.main}`,
      borderRadius: '50%',
      height: fixedSize,
      width: fixedSize,
      boxSizing: 'border-box',
      '&>text': {
        fontSize: { xs: '16px', md: '18px' },
        fontWeight: 700,
      },
    },
  },
  '& .MuiStepLabel-labelContainer': {
    position: 'relative',
    '&>span': {
      fontSize: '1.25rem',
      fontFamily: 'Ubuntu',
      color: 'secondary.main',
      fontWeight: 500,
      lineHeight: 1.1,
      width: { xs: '100%', md: '72%' },
    },
  },
});

const reverseLabel: SxProps = {
  '& .MuiStepLabel-labelContainer': {
    order: { md: -1 },
    display: { md: 'flex' },
    justifyContent: 'flex-end',
    '& .MuiStepLabel-label': { textAlign: { md: 'right' } },
  },
};

export interface VerticalStepsProps {
  title: string;
  stepLabels: string[];
  verticalStepsSx?: {
    root?: SxProps;
  };
}

const VerticalSteps = ({ title, stepLabels, verticalStepsSx }: VerticalStepsProps) => {
  const { t } = useTranslation();

  return (
    <Box component="section" className="section" sx={verticalStepsSx?.root}>
      <Typography variant="h2" sx={titleStyle}>
        {t(title)}
      </Typography>
      <Stepper
        activeStep={-1}
        orientation="vertical"
        connector={<StepConnector sx={connector} />}
        sx={stepper}
      >
        {stepLabels.map((stepLabel: string, index: number) => (
          <Step key={stepLabel}>
            <StepLabel sx={[stepLabelStyle, !!(index % 2) && reverseLabel]}>{t(stepLabel)}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default VerticalSteps;
