import { Box, SxProps } from '@mui/material';
import { useLocation } from 'react-router-dom';
import whatsappLogo from '../../assets/svg/whatsapp.svg';
import { PlexoUrl } from '../../data/urls';

const whatsAppLink: SxProps = {
  backgroundColor: '#25d366',
  borderRadius: '50%',
  cursor: 'pointer',
  display: 'block',
  position: 'fixed',
  bottom: '30px',
  right: '30px',
  height: { xs: '32px', md: '48px' },
  width: { xs: '32px', md: '48px' },
  padding: '0.65rem',
  zIndex: 20,
  transition: 'all 0.3s ease-in-out',
  transform: 'translateY(0)',
  '&:hover': {
    boxShadow: '0 0 2px 2px rgba(0, 0, 0, 0.2)',
    transform: 'translatey(-10px)',
  },
  '& img': { width: '100%', height: '100%' },
};

type State = { fromAd?: boolean; fromEbook?: boolean } | null;

const WhatsappButton = () => {
  const location = useLocation();
  const locationState = location.state as State;
  const isAd: boolean = location.pathname.includes('ads');
  const isFromAd: boolean | undefined = locationState?.fromAd;
  const isFromEbook: boolean | undefined = locationState?.fromEbook;

  return (
    <>
      {!isAd && !isFromAd && !isFromEbook && (
        <Box
          component="a"
          href={PlexoUrl.WHATSAPP}
          target="_blank"
          rel="noreferrer noopener"
          sx={whatsAppLink}
        >
          <img src={whatsappLogo} alt="Whatsapp" />
        </Box>
      )}
    </>
  );
};

export default WhatsappButton;
