import { Error as ErrorIcon } from '@mui/icons-material';
import { FormControl, InputAdornment, InputLabel, TextField, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { FieldInputProps, FormikValues } from 'formik';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import colors from '../../../data/colors';

const formControlStyles: SystemStyleObject<Theme> = { my: '0.5rem' };

const formControlInputStyles: SystemStyleObject<Theme> = { pt: '0.5rem' };

const formControlTextareaStyles: SystemStyleObject<Theme> = { pt: '1.75rem' };

const textFieldStyles: SystemStyleObject<Theme> = {
  height: '3rem',
  color: 'secondary.main',
  '& input': { py: 0 },
  '& textarea': { mb: '1rem', pt: '0.5rem', height: '3.5rem !important' },
};

interface FormTextFieldProps {
  field?: FieldInputProps<FormikValues>;
  form?: any;
  label: string;
  type?: 'text' | 'password' | 'textarea';
  value?: string | number;
  onChange?: (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  disabled?: boolean;
  placeholder?: string;
  sx?: FormTextFieldSx;
}

interface FormTextFieldSx {
  formControl: SystemStyleObject<Theme>;
  label: SystemStyleObject<Theme>;
  textField: SystemStyleObject<Theme>;
}

function FormTextField({
  field,
  form,
  label,
  type = 'text',
  value,
  onChange,
  disabled,
  placeholder,
  sx,
}: FormTextFieldProps) {
  const { t } = useTranslation();
  const errorMessage: string | undefined = form?.touched?.[field?.name!] && form?.errors?.[field?.name!];
  const isTextarea: boolean = type === 'textarea';

  return (
    <FormControl
      variant="standard"
      sx={[
        formControlStyles,
        isTextarea ? formControlTextareaStyles : formControlInputStyles,
        !!sx?.formControl && sx.formControl,
      ]}
      fullWidth
    >
      <InputLabel
        sx={[{ color: 'secondary.main' }, !!errorMessage && { color: colors.red }, !!sx?.label && sx.label]}
        shrink
      >
        {t(label)}
      </InputLabel>
      <TextField
        margin="none"
        variant="standard"
        size="small"
        error={!!errorMessage}
        helperText={errorMessage ? t(errorMessage) : null}
        multiline={isTextarea}
        rows={isTextarea ? 3 : undefined}
        InputProps={{
          sx: [textFieldStyles, !!sx?.textField && sx.textField],
          endAdornment: errorMessage && (
            <InputAdornment position="end">
              <ErrorIcon color="error" sx={{ width: '20px' }} />
            </InputAdornment>
          ),
        }}
        {...field}
        onChange={onChange || field?.onChange}
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        value={value || field?.value}
      />
    </FormControl>
  );
}

export default FormTextField;
