import { BreakpointsOptions, Components, PaletteOptions, Theme } from '@mui/material';
import { TypographyOptions, TypographyStyleOptions } from '@mui/material/styles/createTypography';
import colors from '../data/colors';

//#region breakpoints

// MUI default breakpoint values
const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};

const minMdQuery = `@media screen and (min-width: ${breakpoints?.values?.md}px)`;

//#endregion

//#region palette

export const palette: PaletteOptions = {
  primary: {
    main: colors.teal.regular,
    light: colors.teal.lightest,
  },
  secondary: { main: colors.blue },
  common: { white: colors.white },
  error: { main: colors.red },
};

//#endregion

//#region typography

const headings: TypographyStyleOptions = { fontFamily: 'Ubuntu', fontWeight: 700 };

export const typography: TypographyOptions = {
  fontFamily: 'Fira Sans, Ubuntu',
  h1: {
    ...headings,
    fontSize: '3.125rem',
    [minMdQuery]: { fontSize: '3.375rem' },
  },
  h2: {
    ...headings,
    fontSize: '2.25rem',
    [minMdQuery]: { fontSize: '3rem' },
  },
  h3: {
    ...headings,
    fontSize: '1.125rem',
    [minMdQuery]: { fontSize: '1.4375rem' },
  },
  body1: {
    fontSize: '1rem',
    lineHeight: 1.8,
    [minMdQuery]: { fontSize: '1.125rem' },
  },
};

//#endregion

//#region components

export const components: Components<Omit<Theme, 'components'>> = {
  MuiButton: {
    styleOverrides: {
      root: {
        fontFamily: 'Fira Sans',
        fontSize: '1.125rem',
        lineHeight: '22px',
        borderRadius: '24px',
        padding: '0.75rem 1.25rem',
        textTransform: 'none',
        transition: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:hover, &:focus, &:active': { boxShadow: `0 3px 6px ${colors.transparentBlack}` },
      },
      containedPrimary: {
        color: colors.white,
        border: `1px solid ${colors.teal.regular}`,
        '&:hover, &:focus, &:active': {
          backgroundColor: colors.teal.dark,
          borderColor: colors.teal.dark,
        },
      },
      containedSecondary: {
        backgroundColor: colors.white,
        border: `1px solid ${colors.teal.regular}`,
        color: colors.blue,
        '&:hover, &:focus, &:active': {
          backgroundColor: colors.teal.regular,
          borderColor: colors.teal.dark,
        },
      },
      containedInfo: {
        backgroundColor: colors.blue,
        color: colors.white,
        border: `1px solid ${colors.blue}`,
        '&:hover, &:focus, &:active': { backgroundColor: colors.black },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        flexGrow: 1,
        borderWidth: '1px',
        borderStyle: 'solid',
      },
      standardError: { borderColor: colors.red },
      action: {
        padding: 0,
        alignItems: 'center',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: '1.125rem',
        lineHeight: '22px',
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        fontSize: '1rem',
        lineHeight: '19px',
      },
    },
  },
};

//#endregion
