import { Accordion, AccordionDetails, AccordionSummary, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import colors from '../../../data/colors';
import { plexoProducts } from '../../../data/products';
import { Product } from '../../../types/products.types';
import ChevronDown from '../icons/ChevronDown';
import SolutionItem from './SolutionItem';

interface SolutionsAccordionProps {
  handleClose: () => void;
}

const AccordionSummaryStyle: SxProps = {
  width: 'fit-content',
  marginInline: 'auto',
  color: 'secondary.main',
  fontSize: '1.125rem',
  fontFamily: 'Fira Sans',
  gap: '0.5rem',
  '&.Mui-expanded': { color: 'primary.main' },
};

const AccordionDetailsStyle: SxProps = {
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
};

export const SolutionsAccordion = ({ handleClose }: SolutionsAccordionProps) => {
  const { t } = useTranslation();

  return (
    <Accordion elevation={0} className="section mobile-menu" sx={{ bgcolor: 'unset' }} disableGutters>
      <AccordionSummary
        className="menu-item"
        expandIcon={<ChevronDown color={colors.blue} />}
        sx={AccordionSummaryStyle}
      >
        {t('header-menu-item-1')}
      </AccordionSummary>
      <AccordionDetails className="solutions" sx={AccordionDetailsStyle}>
        {plexoProducts.map((product: Product) => (
          <SolutionItem key={product.shortTitle} product={product} closeMenu={handleClose} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default SolutionsAccordion;
