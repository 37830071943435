import { Box, BoxProps, Button, Grid, SxProps, Theme, Typography } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import DOMPurify from 'dompurify';
import { ImgHTMLAttributes, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from '../../assets/svg/complete-arrow.svg';
import colors from '../../data/colors';
import AdsForm from '../AdsForm';

const textContainer: SxProps<Theme> = {
  mt: { xs: '2.25rem', md: '4.5rem' },
  display: 'flex',
  flexDirection: 'column',
  gap: { xs: '1.5rem' },
  textAlign: { xs: 'center', md: 'left' },
  position: 'relative',
  zIndex: 2,
};

const smallTitleStyle: SystemStyleObject<Theme> = { fontSize: { xs: '2.625rem', md: '3.375rem' } };

const noBreadcrumbs: SxProps<Theme> = { mt: { md: '9rem' } };

const breadcrumbGreaterThan = { '&:hover': { textDecoration: 'none', color: colors.teal.dark } };

const breadcrumbCurrent: SxProps = { cursor: 'pointer', color: colors.teal.dark };

const adsTitleStyles: SxProps<Theme> = {
  fontSize: { xs: '1.125rem', md: '1.3125rem' },
  fontWeight: '500',
  fontFamily: 'Ubuntu',
  color: colors.teal.regular,
  width: '100%',
};

const paragraphStyles: SxProps<Theme> = {
  width: { xs: '100%', md: '80%' },
  fontSize: { xs: '1.125rem', md: '1.375rem' },
  fontFamily: 'Ubuntu',
};

const buttonWrapper: SxProps<Theme> = {
  display: 'flex',
  flexDirection: { xs: 'column', md: 'row' },
  gap: { xs: '0.75rem', md: '1.25rem' },
};

const arrowIconStyles: SxProps<Theme> = {
  width: 'fit-content',
  marginInline: { xs: 'auto', md: '0' },
};

export interface ViewBannerProps {
  id?: string;
  breadcrumbs?: Breadcrumbs;
  adsTitle?: string;
  title: string;
  paragraph?: string;
  paragraphSecondary?: string;
  paragraphWithButton?: ReactNode;
  buttonPrimary?: ButtonProps;
  buttonSecondary?: ButtonProps;
  arrowIconTo?: string;
  image?: BoxProps & ImgHTMLAttributes<HTMLImageElement>;
  image1?: BoxProps & ImgHTMLAttributes<HTMLImageElement>;
  viewBannerSx?: ViewBannerSx;
  stretchFirstContainer?: boolean;
  smallTitle?: boolean;
  children?: ReactNode;
  children2?: ReactNode;
  form?: boolean;
}

interface ViewBannerSx {
  root?: SystemStyleObject<Theme>;
  firstContainer?: SystemStyleObject<Theme>;
  secondContainer?: SystemStyleObject<Theme>;
  title?: SystemStyleObject<Theme>;
}

interface ButtonProps {
  content: string;
  to: string;
  sx?: SxProps;
}

interface Breadcrumbs {
  goToProducts: boolean;
  current: string;
}

const ViewBanner = ({
  id,
  breadcrumbs,
  adsTitle,
  title,
  paragraph,
  paragraphWithButton,
  paragraphSecondary,
  buttonPrimary,
  buttonSecondary,
  arrowIconTo,
  viewBannerSx = {},
  image,
  image1,
  stretchFirstContainer = false,
  smallTitle = false,
  children,
  children2,
  form,
}: ViewBannerProps) => {
  const { t } = useTranslation();

  return (
    <Box className={form ? 'ads-background' : ''}>
      <Box className="section" id={id} sx={viewBannerSx?.root || undefined}>
        {id && <span id="section-anchor" />}
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={stretchFirstContainer ? 10 : 7}
            sx={[
              textContainer,
              !!!breadcrumbs && noBreadcrumbs,
              !!viewBannerSx?.firstContainer && viewBannerSx.firstContainer,
            ]}
          >
            {breadcrumbs && (
              <Box sx={{ display: 'flex', alignSelf: { xs: 'center', md: 'flex-start' } }}>
                <Box
                  component={Link}
                  to={{ pathname: '/', hash: breadcrumbs.goToProducts ? '#products' : undefined }}
                  className="link"
                  sx={{ color: colors.teal.dark }}
                >
                  {breadcrumbs.goToProducts ? t('breadcrumb-products') : 'Home'}
                </Box>
                <Box component="span" className="link" sx={breadcrumbGreaterThan}>
                  &nbsp; &gt; &nbsp;
                </Box>
                <Box
                  component="span"
                  className="link"
                  sx={breadcrumbCurrent}
                  aria-label={t(breadcrumbs.current)}
                  tabIndex={0}
                >
                  {t(breadcrumbs.current)}
                </Box>
              </Box>
            )}
            {adsTitle && (
              <Typography
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t(adsTitle).toUpperCase()) }}
                width="80%"
                sx={adsTitleStyles}
              />
            )}
            <Typography
              variant="h1"
              sx={[smallTitle && smallTitleStyle, !!viewBannerSx?.title && viewBannerSx?.title]}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t(title)) }}
              className="heading-primary"
            />
            {paragraphWithButton}
            {paragraph && (
              <Typography
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t(paragraph)) }}
                width="80%"
                className="text"
                sx={paragraphStyles}
              />
            )}
            {paragraphSecondary && (
              <Typography
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t(paragraphSecondary)) }}
                width="80%"
                className="text"
                sx={paragraphStyles}
              />
            )}
            {children}
            {(buttonPrimary || buttonSecondary) && (
              <Box sx={buttonWrapper}>
                {buttonPrimary && (
                  <Button
                    component={Link}
                    to={buttonPrimary.to}
                    variant="contained"
                    color="primary"
                    sx={buttonPrimary?.sx}
                  >
                    {t(buttonPrimary.content)}
                  </Button>
                )}
                {buttonSecondary && (
                  <Button
                    component="a"
                    href={buttonSecondary.to}
                    variant="contained"
                    color="secondary"
                    sx={buttonSecondary?.sx}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t(buttonSecondary.content)}
                  </Button>
                )}
              </Box>
            )}
            {arrowIconTo && (
              <Box component="a" href={arrowIconTo} sx={arrowIconStyles} className="arrow-icon-down">
                <ArrowIcon />
              </Box>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            sx={[{ position: 'relative' }, !!viewBannerSx?.secondContainer && viewBannerSx.secondContainer]}
          >
            {image && <Box component="img" {...image} alt={t(image.alt!)} />}
            {image1 && <Box component="img" {...image1} alt={t(image1.alt!)} />}
            {form && (
              <Box>
                <AdsForm />
              </Box>
            )}
            {children2}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ViewBanner;
