import Benefits from '../../components/common/Benefits/Benefits';
import Contact from '../../components/common/Contact';
import Seo from '../../components/common/Seo';
import Banner from '../../components/home/Banner/Banner';
import Certification from '../../components/home/Certification/Certification';
import Clients from '../../components/home/Clients/Clients';
import Marquee from '../../components/home/Marquee/Marquee';
import PaymentMethods from '../../components/home/PaymentMethods/PaymentMethods';
import Products from '../../components/home/Products/Products';
import useScroll from '../../hooks/useScroll';
import '../Home/_home.scss';

function Home() {
  useScroll();

  return (
    <>
      <Seo />
      <Banner />
      <Marquee />
      <PaymentMethods />
      <Benefits />
      <Products />
      <Certification />
      <Clients />
      <Contact contactSx={{ root: { mt: '9.25rem' } }} />
    </>
  );
}

export default Home;
