import { Box, Grid } from '@mui/material';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CertificationImg from '../../../assets/img/img-seguridad-pci@2x.png';

function Certification() {
  const { t } = useTranslation();

  return (
    <Box id="certification" className="section">
      <Grid container className="section-container">
        <Grid item xs={12} md={6} className="img-container">
          <div className="button-container">
            <Link to={'/contacto'} className="plexo-button primary-button">
              {t('home-know-more-button')}
            </Link>
          </div>
          <img src={CertificationImg} alt="Plexo certification" />
        </Grid>
        <Grid item xs={12} md={6} className="text-container">
          <h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('home-certification-title')) }} />
          <p
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('home-certification-text')) }}
            className="section-text"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Certification;
