import { Box, SxProps } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCasePos1 from '../../assets/img/use-case-POS-1.png';
import useCasePos2 from '../../assets/img/use-case-POS-2.png';
import useCaseAutomation from '../../assets/img/use-case-automation.png';
import useCaseCorporate from '../../assets/img/use-case-corporate.png';
import useCaseDonations from '../../assets/img/use-case-donations.png';
import useCaseFlexibility from '../../assets/img/use-case-flexibility.png';
import colors from '../../data/colors';
import { UseCase } from '../../types/products.types';
import UseCasesContent from './UseCasesContent';
import UseCasesNavBar from './UseCasesNavBar';

const sectionStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  gap: '3.2rem',
};

const containerStyles: SxProps = {
  color: colors.white,
  fontFamily: 'Fira Sans',
  fontSize: '1.125rem',
  backgroundColor: colors.cardBackground,
  borderRadius: ' 70rem 2rem 2rem 70rem',
  padding: '2.5rem 4.5rem 2.5rem 2.5rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '3.2rem',
};

const reverseContainerStyles: SxProps = {
  ...containerStyles,
  flexDirection: 'row-reverse',
  borderRadius: '2rem 70rem 70rem 2rem',
  padding: '2.5rem 2.5rem 2.5rem 6.3rem',
};

function UseCasesDesktop() {
  const { t } = useTranslation();
  const useCases: UseCase[] = t('px-links.use-cases.items', { returnObjects: true });
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0);

  const images = {
    useCasePos1,
    useCasePos2,
    useCaseCorporate,
    useCaseDonations,
    useCaseFlexibility,
    useCaseAutomation,
  } as Record<string, string>;

  return (
    <>
      <UseCasesNavBar setSelectedItemIndex={setSelectedItemIndex} selectedItemIndex={selectedItemIndex} />
      <Box className="section" sx={sectionStyles}>
        {useCases[selectedItemIndex].examples.map((example, index) => {
          return (
            <Box key={`use-case-${index}`} sx={index % 2 !== 1 ? containerStyles : reverseContainerStyles}>
              <UseCasesContent
                imageFile={images[example.image.file]}
                imageDescription={example.image.alt}
                needText={example.need}
                solutionText={example.solution.text}
                solutionItems={example.solution.items}
              />
            </Box>
          );
        })}
      </Box>
    </>
  );
}

export default UseCasesDesktop;
