import colors from '../../../data/colors';
import { SvgIcon } from '../../../types/svgIcon.types';

const ChevronDown = ({ color }: SvgIcon) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13.544" height="7.739" viewBox="0 0 13.544 7.739">
    <path
      id="chevron-down-solid"
      d="M6.776,167.739a.963.963,0,0,1-.684-.283l-5.8-5.8a.967.967,0,0,1,1.368-1.368l5.12,5.122,5.121-5.121a.967.967,0,1,1,1.368,1.368l-5.8,5.8A.965.965,0,0,1,6.776,167.739Z"
      transform="translate(-0.005 -160)"
      fill={color || colors.white}
    />
  </svg>
);

export default ChevronDown;
