import { Box, Chip, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { ReactComponent as ListItemLogo } from '../../assets/svg/arrow-list.svg';
import colors from '../../data/colors';

const bannerImgContainerStyle: SystemStyleObject<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const bannerItemsContainerStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '0.94rem',
  zIndex: '2',
};

const bannerItemsStyle: SxProps = {
  bgcolor: colors.white,
  fontFamily: 'Fira Sans',
  color: colors.blue,
  borderRadius: '0.75rem',
  boxShadow: `0 3px 6px ${colors.grayShadow}`,
  display: 'flex',
  justifyContent: 'center',
  px: '0.4rem',
  '.MuiChip-icon': { mr: '0', minWidth: '1.5rem' },
};

const bannerItemsMobileStyle: SystemStyleObject<Theme> = {
  ...bannerItemsStyle,
  fontSize: '1rem',
  py: '1.5rem',
};

const bannerItemsDesktopStyle: SystemStyleObject<Theme> = {
  ...bannerItemsStyle,
  fontSize: '0.938rem',
  position: 'absolute',
  minHeight: '6%',
};

export interface itemsProps {
  itemText: string;
  itemCustomStyle: SystemStyleObject<Theme>;
}

export interface BannerImageItemsProps {
  items: itemsProps[];
  imageDesktopSrc: string;
  imageMobileSrc: string;
  imageAlt: string;
  bannerImgDesktopStyle: SxProps;
  bannerImgContainerMargins: SystemStyleObject<Theme>;
  bannerImgMobileStyle: SxProps;
}

function BannerImageItems({
  items,
  imageDesktopSrc,
  imageMobileSrc,
  imageAlt,
  bannerImgDesktopStyle,
  bannerImgContainerMargins,
  bannerImgMobileStyle,
}: BannerImageItemsProps) {
  const theme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('md'));
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  const bannerItems = items.map(({ itemText, itemCustomStyle }: itemsProps) => (
    <Chip
      label={t(itemText)}
      icon={<ListItemLogo />}
      sx={[isMobile ? bannerItemsMobileStyle : bannerItemsDesktopStyle, itemCustomStyle]}
    />
  ));

  return (
    <>
      {isMobile ? (
        <Box sx={[bannerImgContainerStyle, bannerImgContainerMargins]}>
          <Box sx={bannerItemsContainerStyle}>{bannerItems}</Box>
          <Box component="img" src={imageMobileSrc} alt={t(imageAlt)} sx={bannerImgMobileStyle} />
        </Box>
      ) : (
        <Box sx={{ position: 'relative' }}>
          <Box component="img" src={imageDesktopSrc} alt={t(imageAlt)} sx={bannerImgDesktopStyle} />
          {bannerItems}
        </Box>
      )}
    </>
  );
}

export default BannerImageItems;
