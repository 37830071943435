import * as Yup from 'yup';

const formErrorMessages: string = 'contact.viewbanner.form.error-messages';

const emailFormatError: string = `${formErrorMessages}.email-format`;
const lettersOnlyError: string = `${formErrorMessages}.letters-only`;
const requiredFieldError: string = `${formErrorMessages}.required`;
const onlyNumbers: string = `${formErrorMessages}.only-numbers`;

const requiredLetters = Yup.string()
  .matches(/[a-zA-Z]/, lettersOnlyError)
  .required(requiredFieldError);
const requiredEmail = Yup.string()
  .email(emailFormatError)
  .matches(
    /^[a-zA-Z0-9.!$%&*+/=?_{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$$/,
    emailFormatError,
  )
  .required(requiredFieldError);

const ContactSchema = Yup.object().shape({
  fullname: requiredLetters,
  email: requiredEmail,
  message: Yup.string().required(requiredFieldError),
});

const ContactAdsSchema = Yup.object().shape({
  fullname: requiredLetters,
  email: requiredEmail,
});

const ContactGatewaySchema = ContactAdsSchema.shape({
  company: Yup.string()
    .matches(/[a-zA-Z]/, lettersOnlyError)
    .required(requiredFieldError),
  phone: Yup.string().matches(/^[0-9]+$/, onlyNumbers),
});

const ContactEBookSchema = ContactAdsSchema.shape({
  company: Yup.string().matches(/[a-zA-Z]/, lettersOnlyError),
});

const schemas = {
  ContactGatewaySchema,
  ContactEBookSchema,
  ContactSchema,
};

export default schemas;
