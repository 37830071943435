import { Box } from '@mui/material';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import TinySlider from 'tiny-slider-react';
import { clientLogos } from '../../../data/logos';
import { Logo } from '../../../types/logos.types';

function Clients() {
  const { t } = useTranslation();

  const settings = {
    nav: false,
    items: 5,
    gutter: 20,
    controls: false,
    autoplay: true,
    autoplayButtonOutput: false,
    responsive: {
      350: { items: 2 },
      600: { items: 3 },
      780: { items: 4 },
      900: { items: 5 },
    },
  };

  return (
    <Box id="clients" className="section">
      <h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('home-clients-title')) }} />
      <TinySlider settings={settings}>
        {clientLogos.map((logo: Logo) => (
          <img src={logo.img} alt={logo.title} key={logo.title} />
        ))}
      </TinySlider>
    </Box>
  );
}

export default Clients;
