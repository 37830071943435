import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useScroll() {
  const { hash, key } = useLocation();

  useEffect(() => {
    if (hash) {
      const targetElement = document.querySelector(hash);
      targetElement?.scrollIntoView();
    }
  }, [key, hash]);

  return null;
}

export default useScroll;
