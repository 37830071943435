import { Box, MenuItem } from '@mui/material';
import DOMPurify from 'dompurify';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Arrow from '../../../assets/img/complete-arrow@2x.png';
import { Product } from '../../../types/products.types';

interface SolutionItemProps {
  product: Product;
  closeMenu: () => void;
}

const SolutionItem = ({ product, closeMenu }: SolutionItemProps) => {
  const { t } = useTranslation();
  const linkRef = useRef<HTMLAnchorElement>(null);
  const shortTitle: string = t(product.shortTitle);

  const listItemHandler = () => linkRef?.current?.click?.();

  return (
    <MenuItem onClick={listItemHandler} className="solution" aria-label={shortTitle} tabIndex={0}>
      <Box className="solution-title-wrap">
        <img className="solution-icon" src={product.icon} alt={shortTitle} />
        <h4>{shortTitle}</h4>
      </Box>
      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t(product.navText)) }} />
      <div className="solution-button">
        <Link to={product.url} ref={linkRef} onClick={() => closeMenu()} tabIndex={1}>
          <span className="solution-button-text">{t('products-button')}</span>
          <img src={Arrow} alt="Arrow" />
        </Link>
      </div>
    </MenuItem>
  );
};

export default SolutionItem;
