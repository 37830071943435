export enum PlexoUrl {
  CALENDLY = 'https://calendly.com/ktambasco',
  // TODO: Awaiting client's response for Gateway's URL
  PX_GATEWAY = '',
  PX_LINKS = 'https://links.plexo.com.uy/',
  PX_SUBSCRIPTIONS = 'https://suscripciones.handsoft.com.uy/backoffice',
  PX_DONATIONS = 'https://sociosonline.teleton.org.uy/',
  PX_PAYPUNTA = 'https://www.paypunta.com/',
  WHATSAPP = 'https://wa.me/59892615822',
  PX_GATEWAY_DOCUMENTATION = 'https://plexo.gitbook.io/guia-de-integracion/',
}

export enum PlexoEmail {
  BUSINESS = 'comercial@plexo.com.uy',
}
