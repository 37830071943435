import { Box, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import { TFunction, useTranslation } from 'react-i18next';
import RocketIcon from '../../assets/img/icon-rocket.png';
import RocketIcon2x from '../../assets/img/icon-rocket@2x.png';
import ImageHeroBannerMobile from '../../assets/img/img-banner-pasarela-mobile@2x.png';
import ImageHeroBannerDesktop from '../../assets/img/img-banner-pasarela@2x-desktop.png';
import ImageBanner1Mobile from '../../assets/img/img-pasarela-mobile@2x.png';
import ImageBanner4Desktop from '../../assets/img/img-pasarela-potencia@2x-desktop.png';
import ImageBanner1Desktop from '../../assets/img/img-pasarela-sec@2x-desktop.png';
import Banner, { BannerProps } from '../../components/common/Banner';
import Seo from '../../components/common/Seo';
import { ViewBannerProps } from '../../components/common/ViewBanner';
import CustomLogoGrid from '../../components/products/CustomLogoGrid';
import LogoGrid from '../../components/products/LogoGrid';
import ProductLayout from '../../components/products/ProductLayout';
import { gatewayViewIntegrations, gatewayViewPaymentMethods } from '../../data/logos';
import useScroll from '../../hooks/useScroll';

const viewBannerImage: SxProps = {
  width: { xs: '200%', md: '250%' },
  mt: { xs: '-8rem', sm: 'unset' },
  mb: { xs: '-6rem', sm: '-15rem', md: '-26rem ' },
  position: 'relative',
  top: { md: '-8rem' },
  left: { md: '7rem' },
};

const banner1ImgStyle: SxProps = {
  mt: '-10rem',
  mb: { xs: '-2rem', md: '-5rem' },
  position: 'relative',
  top: { md: '5rem' },
  left: { md: '-5rem' },
  width: { xs: '200%', sm: '80%', md: '150%' },
};

const banner1Rocket: SxProps = {
  position: 'absolute',
  width: { xs: '80px', md: '108px' },
  height: { xs: '80px', md: '108px' },
  top: { xs: 0, md: 'auto' },
  right: { xs: '10%', md: 'auto' },
  bottom: { md: 0 },
  left: { md: 0 },
};

const banner4ImgStyle: SxProps = {
  mt: { xs: '-6rem' },
  mb: { xs: '-4rem' },
  width: { xs: '200%', sm: '100%', md: 'auto' },
  position: 'relative',
  left: { md: '2rem' },
};

const gateway: string = 'gateway';
const viewBanner: string = `${gateway}.viewbanner`;
const banner1: string = `${gateway}.banner-1`;
const banner2: string = `${gateway}.banner-2`;
const banner3: string = `${gateway}.banner-3`;
const banner4: string = `${gateway}.banner-4`;

const viewBannerProps = (isMobile: boolean): ViewBannerProps => ({
  breadcrumbs: { goToProducts: true, current: `${viewBanner}.breadcrumb-current` },
  title: `${viewBanner}.title`,
  paragraph: `${viewBanner}.text`,
  buttonPrimary: {
    to: '/contacto',
    content: `${viewBanner}.button-primary`,
    sx: { display: { xs: 'none', sm: 'inline-flex' } },
  },
  // Currently removed, to be reincorporated in the future
  // buttonSecondary: {
  //   to: PlexoUrl.PX_GATEWAY,
  //   content: `${viewBanner}.button-secondary`,
  //   sx: { display: { xs: 'none', sm: 'inline-flex' } },
  // },
  arrowIconTo: '#banner-1',
  image: {
    src: isMobile ? ImageHeroBannerMobile : ImageHeroBannerDesktop,
    alt: `${viewBanner}.img-alt`,
    sx: viewBannerImage,
  },
  viewBannerSx: {
    root: { mb: { xs: '-2.5rem', md: '12rem' } },
    secondContainer: { display: 'flex', justifyContent: 'center' },
    title: { maxWidth: { md: '85%' } },
  },
});

const banner1Hyphen: string = `${banner1}.list-items.item-`;

const banner1Props = (isMobile: boolean, t: TFunction<'translation', undefined>): BannerProps => ({
  title: `${banner1}.title`,
  imageContainerContent: (
    <>
      <Box
        component="img"
        src={isMobile ? ImageBanner1Mobile : ImageBanner1Desktop}
        sx={banner1ImgStyle}
        alt={t(`${viewBanner}.img-alt`)}
        loading="lazy"
      />
      <Box
        component="img"
        src={isMobile ? RocketIcon : RocketIcon2x}
        sx={banner1Rocket}
        alt="Rocket icon"
        loading="lazy"
      />
    </>
  ),
  text: `${banner1}.text`,
  listItems: [`${banner1Hyphen}1`, `${banner1Hyphen}2`, `${banner1Hyphen}3`, `${banner1Hyphen}4`],
  button: { to: '/contacto', content: 'button-start', sx: { mt: { xs: '1rem' } }, alignLeftMobile: true },
  id: 'banner-1',
  bannerSx: { wrapper: { mb: { xs: '-6rem', md: '6rem' } } },
});

const banner2Props = (isMobile: boolean): BannerProps => ({
  title: `${banner2}.title`,
  imageContainerContent: (
    <LogoGrid
      logos={gatewayViewPaymentMethods}
      itemMd={2.4}
      sx={{
        root: { mr: '10px', ml: '10px', mt: '5px', mb: '5px', backgroundSize: '125%' },
      }}
    />
  ),
  mobileLogoSlider: { logos: gatewayViewPaymentMethods },
  subtitle: `${banner2}.subtitle`,
  text: `${banner2}.text`,
  button: {
    to: '/contacto',
    content: `${banner2}.button-${isMobile ? 'mobile' : 'desktop'}`,
    sx: { mt: { md: '1.25rem' } },
  },
  id: 'banner-2',
  customSectionAnchor: true,
  bannerSx: {
    wrapper: { mt: { xs: '-4rem' }, mb: { xs: '2rem', md: '9rem' } },
    textContainer: { pr: { md: '4rem' } },
  },
});

const banner3Props: BannerProps = {
  title: `${banner3}.title`,
  imageContainerContent: <CustomLogoGrid logos={gatewayViewIntegrations} itemsPerRow={[3, 4, 3]} />,
  mobileLogoSlider: { logos: gatewayViewIntegrations },
  text: `${banner3}.text`,
  button: { to: '/recursos', content: `${banner3}.button`, sx: { mt: { md: '1.25rem' } } },
  gridDirection: 'row-reverse',
  bannerSx: {
    wrapper: { mb: { xs: '2rem', md: '10rem' } },
    imageContainer: { height: { md: '350px' } },
  },
};

const banner4Hyphen: string = `${banner4}.list-items.item-`;

const banner4Props = (t: TFunction<'translation', undefined>): BannerProps => ({
  title: `${banner4}.title`,
  imageContainerContent: (
    <Box
      component="img"
      src={ImageBanner4Desktop}
      sx={banner4ImgStyle}
      alt={t(`${banner4}.img-alt`)}
      loading="lazy"
    />
  ),
  listItems: [
    `${banner4Hyphen}1`,
    `${banner4Hyphen}2`,
    `${banner4Hyphen}3`,
    `${banner4Hyphen}4`,
    `${banner4Hyphen}5`,
    `${banner4Hyphen}6`,
    `${banner4Hyphen}7`,
    `${banner4Hyphen}8`,
  ],
  button: {
    to: '/contacto',
    content: 'button-start',
    alignLeftMobile: true,
    sx: { mt: { xs: '0.5rem', md: '1.125rem' } },
  },
  color: 'secondary',
  alignTextLeftMobile: true,
  bannerSx: {
    wrapper: { pt: '10rem', pb: { md: '5rem' } },
    textContainer: { gap: { xs: '1.75rem', md: '3.125rem' } },
    imageContainer: { height: { md: '600px' } },
    listItems: { gap: { xs: '1.75rem 2rem', md: '2.625rem 3rem' } },
  },
});

const Gateway = () => {
  const { t } = useTranslation();
  const theme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('md'));

  useScroll();

  return (
    <>
      <Seo meta={`${gateway}.meta`} />
      <ProductLayout
        viewBannerProps={viewBannerProps(isMobile)}
        reverseBannerProps={banner1Props(isMobile, t)}
        contactProps={{ title: `${gateway}.contact.title` }}
      >
        <Banner {...banner2Props(isMobile)} />
        <Banner {...banner3Props} />
        <Banner {...banner4Props(t)} />
      </ProductLayout>
    </>
  );
};

export default Gateway;
