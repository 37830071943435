import DOMPurify from 'dompurify';
import { ScrollTrigger, Tween } from 'react-gsap';
import { useTranslation } from 'react-i18next';

function ScrolledText() {
  const { t, i18n } = useTranslation();

  return (
    <div className="scrolled-text-container">
      <ScrollTrigger start="-1000px center" end="1000px center" scrub={0.5}>
        <Tween from={{ x: '-100px' }} to={{ x: '100px' }}>
          <p
            className={i18n.language === 'es' ? 'line-1' : 'line-1-en'}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('products-scrolled-text-1')) }}
          />
        </Tween>
        <Tween from={{ x: '100px' }} to={{ x: '-100px' }}>
          <p
            className={i18n.language === 'es' ? 'line-2' : 'line-2-en'}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('products-scrolled-text-2')) }}
          />
        </Tween>
        <Tween from={{ x: '-100px' }} to={{ x: '100px' }}>
          <p
            className={i18n.language === 'es' ? 'line-3' : 'line-3-en'}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('products-scrolled-text-3')) }}
          />
        </Tween>
      </ScrollTrigger>
    </div>
  );
}

export default ScrolledText;
