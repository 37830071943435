import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

interface HelmetProps {
  meta?: string;
  title?: string;
}

const Seo = ({ meta = 'home.meta', title }: HelmetProps) => {
  const { t } = useTranslation();
  const translatedTitle: string = t(title || `${meta}.title`);
  const translatedDescription: string = t(`${meta}.description`);
  const translatedKeywords: string = t(`${meta}.keywords`);

  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{translatedTitle}</title>
      <meta name="description" content={translatedDescription} />
      <meta name="keywords" content={translatedKeywords} />
      {/* Facebook tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={translatedTitle} />
      <meta property="og:description" content={translatedDescription} />
      {/* Twitter tags */}
      <meta name="twitter:creator" content="Plexo" />
      <meta name="twitter:card" content="website" />
      <meta name="twitter:title" content={translatedTitle} />
      <meta name="twitter:description" content={translatedDescription} />
    </Helmet>
  );
};

export default Seo;
