import { Box, Button } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Location, useLocation } from 'react-router-dom';
import { ReactComponent as ChevronDown } from '../../../assets/svg/chevron-down.svg';
import { PlexoUrl } from '../../../data/urls';
import { SolutionsDropdown } from '../Solutions/SolutionsDropdown';
import LanguageToggle from './LanguageToggle';

function NavbarDesktop() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open: boolean = Boolean(anchorEl);
  const location: Location = useLocation();

  const handleClickDropdown = () => {
    const anchorNavbar: HTMLElement | null = document.querySelector('#navbar');
    setAnchorEl((prevValue: HTMLElement | null) => (Boolean(prevValue) ? null : anchorNavbar));
  };

  const handleClose = () => setAnchorEl(null);

  window.onscroll = function () {
    handleClose();
  };

  const menuLinks = useMemo(() => {
    return ['/empresa', '/recursos', '/contacto'].map((route: string, index: number) => (
      <Button
        component={Link}
        key={route}
        to={route}
        className={`menu-item${location.pathname === route && !anchorEl ? '-active' : ''}`}
        onClick={() => setAnchorEl(null)}
      >
        {t(`header-menu-item-${index + 2}`)}
      </Button>
    ));
  }, [anchorEl, location.pathname, t]);

  return (
    <Box className="nav-bar-items-container show">
      <Box className="nav-bar-menu-items">
        <Button
          id="solutions-button"
          aria-controls={open ? 'solutions-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickDropdown}
          className={anchorEl ? 'menu-item-active' : 'menu-item'}
        >
          <span>{t('header-menu-item-1')}</span>
          <ChevronDown />
        </Button>
        <SolutionsDropdown handleClose={handleClose} anchorEl={anchorEl} open={open} />
        {menuLinks}
      </Box>
      <Button
        component="a"
        variant="contained"
        color="secondary"
        className="nav-bar-button"
        href={PlexoUrl.CALENDLY}
        target="_blank"
        rel="noreferrer"
      >
        {t('header-menu-button')}
      </Button>
      <LanguageToggle />
    </Box>
  );
}

export default NavbarDesktop;
