import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Cedu from '../../assets/img/cedu-logo@2x.png';

type State = { fromAd?: boolean } | null;

function AdsFooter() {
  const location = useLocation();
  const locationState = location.state as State;
  const { t } = useTranslation();
  const year: number = new Date().getFullYear();
  const isAd: boolean = location.pathname.includes('ads');
  const isEbook: boolean = location.pathname.includes('ebook');
  const isFromAd: boolean | undefined = locationState?.fromAd;

  return (
    <>
      {(isAd || isFromAd) && !isEbook && (
        <footer id="ads-footer">
          <Box className="section">
            <Box className="footer-ads-content">
              <span className="copyright-container">© {year} Plexo</span>
              <span className="cedu-container">
                {t('footer-member-text')}
                <img src={Cedu} alt="Cedu logo" />
              </span>
            </Box>
          </Box>
        </footer>
      )}
    </>
  );
}

export default AdsFooter;
