import colors from '../../../data/colors';
import { SvgIcon } from '../../../types/svgIcon.types';

function PlusSquare({ color }: SvgIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24.928 24.928">
      <path
        id="plus-square"
        d="M22.257,33.781a.893.893,0,0,1,.89.89V54.257a.893.893,0,0,1-.89.89H2.671a.893.893,0,0,1-.89-.89V34.671a.893.893,0,0,1,.89-.89H22.257m0-1.781H2.671A2.672,2.672,0,0,0,0,34.671V54.257a2.672,2.672,0,0,0,2.671,2.671H22.257a2.672,2.672,0,0,0,2.671-2.671V34.671A2.672,2.672,0,0,0,22.257,32ZM18.918,43.462H13.465V38.009a.67.67,0,0,0-.668-.668H12.13a.67.67,0,0,0-.668.668v5.453H6.009a.67.67,0,0,0-.668.668V44.8a.67.67,0,0,0,.668.668h5.453v5.453a.67.67,0,0,0,.668.668H12.8a.67.67,0,0,0,.668-.668V45.465h5.453a.67.67,0,0,0,.668-.668V44.13A.67.67,0,0,0,18.918,43.462Z"
        transform="translate(0 -32)"
        fill={color || colors.blue}
      />
    </svg>
  );
}

export default PlusSquare;
