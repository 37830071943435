import { Box, Grid, SxProps, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { Plugin } from '../data/plugins';
import PluginCard from './PluginCard';

export interface PluginsProps {
  title: string;
  plugins: Plugin[];
  pluginsSx?: PluginsSx;
}

interface PluginsSx {
  root?: SxProps;
}

const Plugins = ({ title, plugins, pluginsSx }: PluginsProps) => {
  const { t } = useTranslation();

  return (
    <Box component="section" className="section" sx={pluginsSx?.root}>
      <Typography
        variant="h2"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t(title)) }}
        className="heading-primary"
        mb="3rem"
      />
      <Grid container spacing={2} position="relative">
        {plugins.map((plugin: Plugin) => (
          <PluginCard {...plugin} key={plugin.title} />
        ))}
      </Grid>
    </Box>
  );
};

export default Plugins;
