const colors = {
  teal: {
    lightest: '#dff6ff',
    light: '#6eccb1',
    regular: '#59c5a9',
    dark: '#4fafb6',
    darker: '#008aae',
    darkest: '#00b2b8',
    resourcesCard: '#187177',
  },
  skyblue: {
    light: '#82c1fa',
    regular: '#9cbffb',
    dark: '#6991f8',
    lightest: '#9cbffb29',
    transparent: '#9cbffbcf',
    lightTransparent: '#9cbffb80',
  },
  blue: '#000c55',
  blueShadow: '#9cbffb34',
  red: '#ff0000',
  purple: '#5d53a5',
  gray: '#707070',
  black: '#00062e',
  transparentBlack: '#00000029',
  blackShadow: '#000c5529',
  white: '#ffffff',
  lightGreen: '#59c5a933',
  grayShadow: '#000C5529',
  cardBackground: '#ffffff33',
  darkGreen: '#4FAFB6',
};

export default colors;
