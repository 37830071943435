const checkIndexProperties = <T>(
  array: T[],
  indexToCheck: number,
): { isOddIndex: boolean; isLastIndex: boolean } => {
  const isOddIndex = indexToCheck % 2 === 1;
  const isLastIndex = indexToCheck === array.length - 1;

  return { isOddIndex, isLastIndex };
};

export const useCasesHelper = {
  checkIndexProperties,
};
