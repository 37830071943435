import FaqAccordion from '../components/FaqAccordion';
import { Faq, Product } from '../types/products.types';

interface FaqsAccordionsProps {
  product: Product;
}

function FaqsAccordions({ product }: FaqsAccordionsProps) {
  return (
    <>
      {product.faqs?.map((faq: Faq) => {
        return <FaqAccordion faq={faq} key={faq.question} />;
      })}
    </>
  );
}

export default FaqsAccordions;
