import { Box, Grid, SxProps, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RadialGradient from '../assets/img/radial-gradient-1.png';
import Seo from '../components/common/Seo';
import ViewBanner from '../components/common/ViewBanner';
import FaqsAccordion from '../components/FaqsAccordions';
import FaqsMenuDesktop from '../components/FaqsMenuDesktop';
import FaqsMenuMobile from '../components/FaqsMenuMobile';
import TabPanel from '../components/TabPanel';
import colors from '../data/colors';
import { plexoProducts } from '../data/products';
import { Product } from '../types/products.types';

const faqs: string = 'faqs';
const viewbanner: string = `${faqs}.viewbanner`;

const overlay: SxProps<Theme> = { backgroundImage: `url(${RadialGradient})`, backgroundRepeat: 'no-repeat' };

const overlayStyle: SxProps<Theme> = {
  ...overlay,
  backgroundSize: { md: '56%', xl: '43%' },
  backgroundPositionX: { md: '-190px', xl: '40px' },
  backgroundPositionY: '165px',
};

const overlayStyleMobile: SxProps<Theme> = {
  ...overlay,
  backgroundSize: '540px 360px',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: '-60px',
  backgroundPositionY: '140px',
};

const textStyle: SxProps<Theme> = {
  fontSize: '0.875rem',
  fontFamily: 'Ubuntu',
  color: colors.blue,
  mt: '2.2rem',
};

function Faqs() {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const theme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();

  return (
    <>
      <Seo meta={`${faqs}.meta`} />
      <Box sx={isMobile ? overlayStyleMobile : overlayStyle}>
        <ViewBanner
          breadcrumbs={{ goToProducts: false, current: `${viewbanner}.breadcrumb-current` }}
          title={`${viewbanner}.title`}
          stretchFirstContainer
          viewBannerSx={{ title: { maxWidth: { md: '80%' } } }}
        />
        <Box className="section">
          {isMobile && (
            <Typography variant="body1" sx={textStyle}>
              {t('faqs.select-label')}
            </Typography>
          )}
          <Grid container sx={{ my: isMobile ? '0' : '5rem' }}>
            <Grid item xs={12} md={4} sx={{ minHeight: isMobile ? 'unset' : '640px' }}>
              <Box sx={{ display: isMobile ? 'block' : 'none', mb: '3.75rem' }}>
                <FaqsMenuMobile setTabIndex={setTabIndex} tabIndex={tabIndex} />
              </Box>
              <Box sx={{ display: isMobile ? 'none' : 'block' }}>
                <FaqsMenuDesktop setTabIndex={setTabIndex} tabIndex={tabIndex} />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{ minHeight: isMobile ? '450px' : 'unset', mb: isMobile ? '5rem' : 'unset' }}
            >
              {plexoProducts.map((product: Product, index: number) => (
                <TabPanel value={tabIndex} index={index} key={`${product.title}_${index}`}>
                  <FaqsAccordion product={product} />
                </TabPanel>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default Faqs;
