import { Box, MenuItem, SxProps } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { supportedLanguages } from '../../../i18n/config';

interface LanguageToggleProps {
  isInsideMenu?: boolean;
}

function LanguageToggle({ isInsideMenu = false }: LanguageToggleProps) {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<string>(i18n.language);

  const changeLanguage = (lang: string) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem('localLanguage', i18n.language);
  };

  const languageProps = (chosenLanguage: string) => ({
    content: chosenLanguage.toUpperCase(),
    onClick: () => changeLanguage(chosenLanguage),
    className: `language-button ${language === chosenLanguage && 'active'}`,
  });

  const langElements = supportedLanguages.map((language: string, index: number) => {
    const props = languageProps(language as string);

    const menuItemStyle: SxProps | undefined =
      index === 0
        ? { marginLeft: 'auto' }
        : index === i18n.languages.length - 1
        ? { marginRight: 'auto' }
        : undefined;

    return isInsideMenu ? (
      <MenuItem key={language} onClick={props.onClick} className={props.className} sx={menuItemStyle}>
        {props.content}
      </MenuItem>
    ) : (
      <button key={language} onClick={props.onClick} className={props.className}>
        {props.content}
      </button>
    );
  });

  return isInsideMenu ? <>{langElements}</> : <Box className="language-wrap">{langElements}</Box>;
}

export default LanguageToggle;
