import { ContactFormAdsValues } from '../components/AdsForm';
import { config } from '../config/config';
import axios from './axios';

interface MailServiceProps extends ContactFormAdsValues {
  captchaToken: string;
  subject: string;
}

async function sendAdsContactMail({
  fullname,
  company,
  email,
  phone,
  subject,
  captchaToken,
}: MailServiceProps) {
  try {
    await axios.post(config.backendUrlAds!, {
      subject,
      fromFullName: fullname,
      fromCompany: company,
      fromEmail: email,
      fromPhone: phone,
      captchaToken,
    });

    return true;
  } catch (err) {
    return false;
  }
}

export default sendAdsContactMail;
