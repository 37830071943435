import { Box, SxProps, Theme, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Arrow from '../../assets/img/complete-arrow@2x.png';
import colors from '../../data/colors';
import { Product } from '../../types/products.types';

const card: SxProps<Theme> = {
  backgroundColor: colors.white,
  textAlign: 'left',
  borderTopLeftRadius: '1000px',
  borderTopRightRadius: '1000px',
  borderBottomLeftRadius: '150px',
  borderBottomRightRadius: '150px',
  padding: '25px',
  display: 'flex',
  flexDirection: 'column',
  margin: '0 15px',
  img: { width: '100%' },
};

const textContainer: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
  h3: { color: colors.blue, margin: '10px 0', height: '55px' },
};

const btnContainer: SxProps<Theme> = {
  a: {
    textDecoration: 'none',
    fontFamily: 'Fira Sans',
    fontSize: { xs: '1rem', md: '1.125rem' },
    color: colors.teal.light,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },
  img: { width: '15%', marginLeft: '10px' },
};

interface ProductCardProps {
  product: Product;
}

const ProductCard = ({ product }: ProductCardProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={card}>
      <img src={product.img} alt={product.title} />
      <Box sx={textContainer}>
        <Typography variant="h3">{t(product.title)}</Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t(product.text)) }}
          variant="body1"
          className="text"
          sx={{ height: '180px' }}
        />
        <Box sx={btnContainer}>
          <Link to={product.url}>
            {t('products-button')}
            <img src={Arrow} alt="Arrow" />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductCard;
