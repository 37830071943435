import { SxProps } from '@mui/material';
import { Location, useLocation } from 'react-router-dom';
import ImageHeroBanner from '../assets/img/img-404@2x.png';
import Seo from '../components/common/Seo';
import ViewBanner from '../components/common/ViewBanner';

const notFound: string = 'not-found';
const viewBanner: string = `${notFound}.viewbanner`;

const viewBannerImage: SxProps = {
  width: { xs: '160%', md: '170%' },
  marginTop: { xs: '-7.5rem', md: '-9rem' },
  marginBottom: { xs: '10rem', md: '-9rem' },
  marginLeft: { xs: '-4rem', md: '-8rem' },
};

type State = { fromAd?: boolean } | null;

function NotFound() {
  const location: Location = useLocation();
  const locationState = location.state as State;

  return (
    <>
      <Seo title={`${notFound}.meta.title`} />
      <ViewBanner
        title={`${viewBanner}.title`}
        paragraph={`${viewBanner}.text`}
        buttonPrimary={{
          to: locationState?.fromAd ? '/ads/pasarela' : '/',
          content: `${viewBanner}.button-primary`,
        }}
        image={{ src: ImageHeroBanner, alt: `${viewBanner}.img-alt`, sx: viewBannerImage }}
        viewBannerSx={{
          firstContainer: {
            '& h1': { fontSize: '2.625rem' },
            mt: { sm: '2rem', md: '8rem' },
            mb: { xs: 'unset', sm: '14rem' },
          },
        }}
      />
    </>
  );
}

export default NotFound;
