import { Box, SxProps, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import colors from '../../data/colors';
import ListItems from '../common/ListItems/ListItems';

const subtitleStyles: SxProps = {
  color: colors.teal.lightest,
  fontFamily: 'Ubuntu',
  fontSize: { xs: '1.125rem', md: '1.4375rem' },
  marginBottom: '1rem',
};

const textStyles: SxProps = {
  color: colors.teal.lightest,
  fontFamily: 'Fira Sans',
  fontSize: { xs: '1rem', md: '1.125rem' },
  lineHeight: { xs: '1.2', md: '1.8' },
};

interface UseCaseParagraphProps {
  subtitle: string;
  text: string;
  items?: string[];
}

function UseCaseParagraph({ subtitle, text, items }: UseCaseParagraphProps) {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="h3" className="heading-primary" sx={subtitleStyles}>
        {t(subtitle)}
      </Typography>
      <Typography variant="body1" sx={textStyles}>
        {DOMPurify.sanitize(text)}
      </Typography>
      {items && <ListItems texts={items} color="secondary" sx={{ marginTop: '1rem' }} />}
    </Box>
  );
}

export default UseCaseParagraph;
