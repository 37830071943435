import { Button, Menu, MenuItem, SxProps } from '@mui/material';
import { Squash as Hamburger } from 'hamburger-react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import colors from '../../../data/colors';
import { PlexoUrl } from '../../../data/urls';
import SolutionsAccordion from '../Solutions/SolutionsAccordion';
import LanguageToggle from './LanguageToggle';

const MenuListProps: SxProps = {
  display: 'flex',
  flexWrap: 'wrap',
  paddingBlock: '1.75rem',
  bgcolor: colors.teal.lightest,
};

const MenuItemStyle: SxProps = {
  width: '100%',
  justifyContent: 'center',
  pointerEvents: 'none',
  '&:hover': { bgcolor: 'transparent' },
};

const NavbarMobile = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [showBurguerMenu, setShowBurguerMenu] = useState<boolean>(false);
  const anchorAppbar: HTMLElement | null = document.querySelector('#appbar');

  useEffect(() => setShowBurguerMenu(false), [location.pathname]);

  const handleClickBurguer = () => {
    setShowBurguerMenu((prevValue) => !prevValue);
    handlePopoverPosition();
  };

  const handlePopoverPosition = () => anchorAppbar && setAnchorEl(anchorAppbar);

  const handleClose = () => setAnchorEl(null);

  const menuLinks = useMemo(
    () =>
      ['/empresa', '/recursos', '/contacto'].map((route: string, index: number) => (
        <MenuItem key={route} sx={MenuItemStyle}>
          <Button
            component={Link}
            to={route}
            className={`menu-item${location.pathname === route ? '-active' : ''}`}
            color={location.pathname === route ? 'primary' : 'secondary'}
            sx={{ pointerEvents: 'all' }}
          >
            {t(`header-menu-item-${index + 2}`)}
          </Button>
        </MenuItem>
      )),
    [location.pathname, t],
  );

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={showBurguerMenu}
        onClose={handleClickBurguer}
        PaperProps={{ sx: { width: '100%' } }}
        MenuListProps={{ sx: MenuListProps }}
      >
        <SolutionsAccordion handleClose={handleClose} />
        {menuLinks}
        <MenuItem sx={MenuItemStyle}>
          <Button
            component="a"
            variant="contained"
            color="secondary"
            className="nav-bar-button"
            href={PlexoUrl.CALENDLY}
            target="_blank"
            rel="noreferrer"
            sx={{ cursor: 'pointer', pointerEvents: 'all', width: '100%', maxWidth: { md: 'fit-content' } }}
          >
            {t('header-menu-button')}
          </Button>
        </MenuItem>
        <LanguageToggle isInsideMenu />
      </Menu>
      <div className="burguer-btn">
        <Hamburger
          label="Show menu"
          size={28}
          color={colors.teal.light}
          distance="lg"
          toggled={showBurguerMenu}
          toggle={handleClickBurguer}
        />
      </div>
    </>
  );
};

export default NavbarMobile;
