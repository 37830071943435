import colors from '../../../data/colors';
import { SvgIcon } from '../../../types/svgIcon.types';

function MinusSquare({ color }: SvgIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24.864 24.864">
      <path
        id="minus-square"
        d="M22.2,33.776a.891.891,0,0,1,.888.888V54.2a.891.891,0,0,1-.888.888H2.664a.891.891,0,0,1-.888-.888V34.664a.891.891,0,0,1,.888-.888H22.2M22.2,32H2.664A2.665,2.665,0,0,0,0,34.664V54.2a2.665,2.665,0,0,0,2.664,2.664H22.2A2.665,2.665,0,0,0,24.864,54.2V34.664A2.665,2.665,0,0,0,22.2,32ZM18.87,45.431a.668.668,0,0,0,.666-.666V44.1a.668.668,0,0,0-.666-.666H5.994a.668.668,0,0,0-.666.666v.666a.668.668,0,0,0,.666.666Z"
        transform="translate(0 -32)"
        fill={color || colors.teal.light}
      />
    </svg>
  );
}

export default MinusSquare;
