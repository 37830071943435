import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources: Resource = {
  es: { translations: require('./locales/es/translations.json') },
  en: { translations: require('./locales/en/translations.json') },
};

export const supportedLanguages: string[] = Object.keys(resources);

i18n.use(initReactI18next).init({
  fallbackLng: localStorage.getItem('localLanguage') || 'es',
  lng: localStorage.getItem('localLanguage') || 'es',
  resources: resources,
  ns: ['translations'],
  defaultNS: 'translations',
});

export default i18n;
