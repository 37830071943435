import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import AdsFooter from '../components/common/AdsFooter';
import Footer from '../components/common/Footer/Footer';
import Appbar from '../components/common/Header/AppBar';
import WhatsappButton from '../components/common/WhatsAppButton';
import About from './About';
import Contact from './Contact';
import Faqs from './Faqs';
import Home from './Home/Home';
import NotFound from './NotFound';
import Resources from './Resources';
import ThankYou from './ThankYou';
import EBook from './ads/EBook';
import GatewayAd from './ads/GatewayAd';
import Donations from './products/Donations';
import Gateway from './products/Gateway';
import Invoice from './products/Invoice';
import PlexoLinks from './products/PlexoLinks';
import PropTech from './products/PropTech';
import Subscriptions from './products/Subscriptions';

function Main() {
  const location = useLocation();
  const notFound: boolean = location.pathname === '/404';

  return (
    <div className={`main ${notFound ? 'full' : ''}`}>
      <Appbar />
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/productos/pasarela" element={<Gateway />} />
          <Route path="/productos/suscripciones" element={<Subscriptions />} />
          <Route path="/productos/plexo-links" element={<PlexoLinks />} />
          <Route path="/productos/donaciones" element={<Donations />} />
          <Route path="/productos/plexo-invoice" element={<Invoice />} />
          <Route path="/productos/proptech" element={<PropTech />} />
          <Route path="/empresa" element={<About />} />
          <Route path="/recursos" element={<Resources />} />
          <Route path="/gracias" element={<ThankYou />} />
          <Route path="/contacto" element={<Contact />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/ads/pasarela" element={<GatewayAd />} />
          <Route path="/ads/ebook" element={<EBook />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </div>
      <Footer />
      <AdsFooter />
      <WhatsappButton />
    </div>
  );
}

export default Main;
