import { Box, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import { TFunction, useTranslation } from 'react-i18next';
import ImageHeroBannerMobile from '../../assets/img/img-banner-suscri-mobile@2x.png';
import ImageHeroBannerDesktop from '../../assets/img/img-banner-suscri@2x.png';
import ImageBanner1Mobile from '../../assets/img/img-suscri-1-mobile@2x.png';
import ImageBanner1Desktop from '../../assets/img/img-suscri-1@2x.png';
import ImageBanner2 from '../../assets/img/img-suscri-2@2x.png';
import ImageBanner3 from '../../assets/img/img-suscri-3@2x.png';
import Banner, { BannerProps } from '../../components/common/Banner';
import { ContactProps } from '../../components/common/Contact';
import Seo from '../../components/common/Seo';
import { ViewBannerProps } from '../../components/common/ViewBanner';
import ProductLayout from '../../components/products/ProductLayout';
import { PlexoUrl } from '../../data/urls';

const subscriptions: string = 'subscriptions';
const viewbanner: string = `${subscriptions}.viewbanner`;
const banner1: string = `${subscriptions}.banner-1`;
const banner2: string = `${subscriptions}.banner-2`;
const banner3: string = `${subscriptions}.banner-3`;
const contact: string = `${subscriptions}.contact`;
const listItemsHyphen: string = `${subscriptions}.banner-2.list-items.item-`;
const listItems: string[] = [
  `${listItemsHyphen}1`,
  `${listItemsHyphen}2`,
  `${listItemsHyphen}3`,
  `${listItemsHyphen}4`,
  `${listItemsHyphen}5`,
  `${listItemsHyphen}6`,
];

const viewBannerImgStyle: SxProps = {
  width: { xs: '250%', md: '230%' },
  position: 'relative',
  top: { xs: '-12rem', md: '-10rem' },
  left: { xs: '-15rem', md: '-11rem' },
};

const banner1ImgStyle: SxProps = {
  width: { xs: '180%', md: '130%' },
  position: { xs: 'unset', md: 'relative' },
  left: '-8rem',
  mb: { xs: '-5rem' },
  mt: { xs: '-4rem' },
};

const banner2ImgStyle: SxProps = {
  width: { xs: '180%', md: '150%' },
  position: { xs: 'unset', md: 'relative' },
  left: '5rem',
};

const banner3ImgStyle: SxProps = {
  width: { xs: '180%', md: '120%' },
  position: { xs: 'unset', md: 'relative' },
  left: '-3rem',
  mt: { xs: '-5rem' },
};

const viewBannerProps = (isMobile: boolean): ViewBannerProps => ({
  breadcrumbs: { goToProducts: true, current: `${viewbanner}.breadcrumb-current` },
  title: `${viewbanner}.title`,
  paragraph: `${viewbanner}.text`,
  buttonPrimary: { to: '/contacto', content: `${viewbanner}.button-primary` },
  buttonSecondary: {
    to: PlexoUrl.PX_SUBSCRIPTIONS,
    content: `${viewbanner}.button-secondary`,
  },
  arrowIconTo: '#banner-1',
  image: {
    src: isMobile ? ImageHeroBannerMobile : ImageHeroBannerDesktop,
    alt: `${viewbanner}.img-alt`,
    sx: viewBannerImgStyle,
  },
  smallTitle: true,
  viewBannerSx: { root: { mb: { xs: '-22rem', md: '-24rem' } }, title: { maxWidth: { md: '90%' } } },
});

const banner1props = (isMobile: boolean, t: TFunction<'translation', undefined>): BannerProps => ({
  title: `${banner1}.title`,
  text: `${banner1}.text`,
  button: { to: '/contacto', content: `${banner1}.button`, sx: { mt: { xs: '25px' } } },
  imageContainerContent: (
    <Box
      component="img"
      src={isMobile ? ImageBanner1Mobile : ImageBanner1Desktop}
      sx={banner1ImgStyle}
      alt={t(`${banner1}.img-alt`)}
    />
  ),
  id: 'banner-1',
  bannerSx: { wrapper: { mb: { md: '-2rem' } } },
});

const banner2props = (t: TFunction<'translation', undefined>): BannerProps => ({
  title: `${banner2}.title`,
  listItems: listItems,
  button: {
    to: '/contacto',
    content: `${banner2}.button`,
    alignLeftMobile: true,
    sx: { mt: { xs: '25px' } },
  },
  alignTextLeftMobile: true,
  imageContainerContent: (
    <Box component="img" src={ImageBanner2} sx={banner2ImgStyle} alt={t(`${banner2}.img-alt`)} />
  ),
  bannerSx: { wrapper: { mb: { md: '-2rem' } } },
});

const banner3props = (t: TFunction<'translation', undefined>): BannerProps => ({
  title: `${banner3}.title`,
  text: `${banner3}.text`,
  button: { to: '/recursos', content: `${banner3}.button` },
  gridDirection: 'row-reverse',
  imageContainerContent: (
    <Box component="img" src={ImageBanner3} sx={banner3ImgStyle} alt={t(`${banner3}.img-alt`)} />
  ),
  bannerSx: { wrapper: { mb: { md: '4rem' } } },
});

const contactProps: ContactProps = {
  title: `${contact}.title`,
};

const Subscriptions = () => {
  const theme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();

  return (
    <>
      <Seo meta={`${subscriptions}.meta`} />
      <ProductLayout
        viewBannerProps={viewBannerProps(isMobile)}
        reverseBannerProps={banner1props(isMobile, t)}
        contactProps={contactProps}
      >
        <Banner {...banner2props(t)} />
        <Banner {...banner3props(t)} />
      </ProductLayout>
    </>
  );
};

export default Subscriptions;
