import { Menu, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import { plexoProducts } from '../../../data/products';
import { Product } from '../../../types/products.types';
import SolutionItem from './SolutionItem';

const PaperStyle: SxProps = {
  width: '80%',
  maxWidth: '1200px',
  position: 'relative',
  borderRadius: '20px',
};

interface SolutionsDropdownProps {
  handleClose: () => void;
  anchorEl: HTMLElement | null;
  open: boolean;
}

export const SolutionsDropdown = ({ handleClose, anchorEl, open }: SolutionsDropdownProps) => {
  const theme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('lg'));

  const filteredProducts = plexoProducts.filter(
    (product: Product) => !product.shortTitle.includes('invoice'),
  );

  return (
    <Menu
      id="solutions-menu"
      anchorEl={anchorEl}
      open={!isMobile && open}
      onClose={handleClose}
      sx={{ bottom: 'unset', top: '1.25rem' }}
      PaperProps={{ sx: PaperStyle }}
      MenuListProps={{
        'aria-labelledby': 'solutions-button',
        className: 'solutions',
        sx: { '& li': { pb: '1.125rem' } },
      }}
    >
      {filteredProducts.map((product: Product) => (
        <SolutionItem key={product.shortTitle} product={product} closeMenu={handleClose} />
      ))}
    </Menu>
  );
};

export default SolutionsDropdown;
