import Facebook from '../assets/svg/facebook-square.svg';
import Instagram from '../assets/svg/instagram-square.svg';
import LinkedIn from '../assets/svg/linkedin.svg';
import Twitter from '../assets/svg/twitter-square.svg';

export interface SocialNetwork {
  href: string;
  alt: string;
  src: string;
}

export const socialNetworks: SocialNetwork[] = [
  { href: 'https://www.facebook.com/plexouruguay', alt: 'Facebook', src: Facebook },
  { href: 'https://twitter.com/plexouruguay', alt: 'Twitter', src: Twitter },
  { href: 'https://www.instagram.com/plexouruguay', alt: 'Instagram', src: Instagram },
  { href: 'https://www.linkedin.com/company/plexouruguay', alt: 'LinkedIn', src: LinkedIn },
];
