import { Box, SxProps, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import colors from '../../data/colors';
import { PlexoUrl } from '../../data/urls';

const contactContainer: SxProps<Theme> = {
  width: '100%',
  backgroundColor: colors.skyblue.regular,
  backgroundImage: `linear-gradient(20deg, ${colors.skyblue.regular}, ${colors.teal.regular} 97%)`,
  padding: '8% 0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const textContainer: SxProps<Theme> = {
  width: { xs: '65%', md: '42%' },
  padding: '6% 9%',
  backgroundColor: colors.white,
  position: 'relative',
  textAlign: 'center',
  color: colors.blue,
  display: 'flex',
  flexDirection: 'column',
  gap: { xs: '1rem', md: '1.5rem' },

  '& p': {
    fontFamily: 'Fira Sans',
    fontSize: { xs: '1.375rem', md: '1.25rem' },
    marginBottom: { xs: '1rem', md: '1.5rem' },
  },
};

const btnContainer: SxProps<Theme> = {
  display: 'flex',
  flexDirection: { xs: 'column', md: 'row' },
  justifyContent: 'center',
  gap: '1rem',
  alignItems: 'center',

  '& a ': {
    width: { xs: '80%', md: 'fit-content' },
  },
};

export interface ContactProps {
  title?: string;
  contactSx?: ContactSx;
  textContainerCustom?: SystemStyleObject<Theme>;
}

interface ContactSx {
  root?: SystemStyleObject<Theme>;
}

const Contact = ({ title, contactSx = {}, textContainerCustom = {} }: ContactProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ ...contactContainer, ...contactSx.root }}>
      <Box sx={{ ...textContainer, ...textContainerCustom }}>
        <h2
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t(title || 'home.contact.title')) }}
          className="heading-primary"
        />
        {/*TODO: change to Typography variant="body1" */}
        <p>{t('home.contact.text')}</p>
        <Box sx={btnContainer}>
          <Link to="/contacto" className="plexo-button primary-button">
            {t('home.contact.btn-primary')}
          </Link>
          <a
            className="plexo-button secondary-button"
            href={PlexoUrl.CALENDLY}
            target="_blank"
            rel="noreferrer"
          >
            {t('home.contact.btn-secondary')}
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;
