import { useEffect } from 'react';
import { Location, useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname }: Location = useLocation();

  useEffect(() => window.scrollTo(0, 0), [pathname]);

  return null;
}

export default ScrollToTop;
