import { Accordion, AccordionDetails, AccordionSummary, Divider, SxProps, Typography } from '@mui/material';
import { useState } from 'react';
import useCasePos1 from '../../assets/img/use-case-POS-1-mobile.png';
import useCasePos2 from '../../assets/img/use-case-POS-2-mobile.png';
import useCaseAutomation from '../../assets/img/use-case-automation-mobile.png';
import useCaseCorporate from '../../assets/img/use-case-corporate-mobile.png';
import useCaseDonations from '../../assets/img/use-case-donations-mobile.png';
import useCaseFlexibility from '../../assets/img/use-case-flexibility-mobile.png';
import colors from '../../data/colors';
import { UseCase } from '../../types/products.types';
import { useCasesHelper } from '../../utis/useCases.helper';
import ChevronDown from '../common/icons/ChevronDown';
import UseCasesContent from './UseCasesContent';

const accordionStyles: SxProps = {
  backgroundColor: colors.cardBackground,
  width: '100%',
  marginBottom: '1rem',
  borderRadius: '0.5rem ',
  ':first-of-type': {
    borderRadius: '0.5rem',
  },
  ':last-of-type': {
    borderRadius: '0.5rem',
  },
};

const accordionSummaryStyles: SxProps = {
  height: '4.4rem',
  borderRadius: '1rem',
  paddingX: '1.5rem',
  '&.Mui-expanded': {
    borderBottom: `1px solid ${colors.skyblue.lightest}`,
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
  },
};

const accordionDetailsStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingX: '1.5rem',
  paddingY: '2.5rem',
};

const dividerStyles: SxProps = {
  width: '100%',
  marginY: '3rem',
  backgroundColor: colors.skyblue.lightest,
};

interface UseCaseAccordionProps {
  useCase: UseCase;
}

function UseCaseAccordion({ useCase }: UseCaseAccordionProps) {
  const [expanded, setExpanded] = useState<boolean>(false);

  const images = {
    useCasePos1,
    useCasePos2,
    useCaseCorporate,
    useCaseDonations,
    useCaseFlexibility,
    useCaseAutomation,
  } as Record<string, string>;

  const toggleExpanded = () => setExpanded((preValue) => !preValue);

  return (
    <Accordion
      disableGutters
      elevation={0}
      expanded={expanded}
      onChange={toggleExpanded}
      sx={accordionStyles}
    >
      <AccordionSummary sx={accordionSummaryStyles} expandIcon={<ChevronDown color={colors.teal.lightest} />}>
        <Typography variant="h3" sx={{ fontSize: '1rem' }}>
          {useCase.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={accordionDetailsStyles}>
        {useCase.examples.map((example, index) => {
          const { isLastIndex } = useCasesHelper.checkIndexProperties(useCase.examples, index);
          return (
            <>
              <UseCasesContent
                key={index}
                imageFile={images[example.image.file]}
                imageDescription={example.image.alt}
                needText={example.need}
                solutionText={example.solution.text}
                solutionItems={example.solution.items}
              />
              {useCase.examples.length !== 1 && !isLastIndex && <Divider sx={dividerStyles} />}
            </>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}

export default UseCaseAccordion;
