import { Box, Theme, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PlexoLogoWhite from '../../../assets/img/logo-plexo-white@2x.png';
import PlexoLogo from '../../../assets/img/plexo-logo-color@2x.png';
import NavbarDesktop from './NavbarDesktop';
import NavbarMobile from './NavbarMobile';

type State = { fromAd?: boolean; fromEbook?: boolean } | null;

function Header() {
  const [userHasScrolled, setUserHasScrolled] = useState<boolean>(false);
  const theme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('lg'));
  const location = useLocation();
  const locationState = location.state as State;
  const notFound: boolean = location.pathname === '/404';
  const isAd: boolean = location.pathname.includes('ads');
  const isFromAd: boolean | undefined = locationState?.fromAd;
  const isEbook: boolean = location.pathname.includes('ebook');
  const isFromEbook: boolean | undefined = locationState?.fromEbook;

  useEffect(() => {
    const onScroll = () => setUserHasScrolled(window.scrollY > 0);
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  if (isEbook || isFromEbook) return null;

  return (
    <Box
      component="header"
      id="appbar"
      className={`nav-bar ${userHasScrolled ? 'scrolling' : ''} ${
        notFound ? 'nav-bar-404' : isAd ? 'nav-bar-ads' : isFromAd ? 'nav-bar-from-ads' : ''
      }`}
    >
      <Box id="navbar" component="nav" className="nav-bar-wrap section">
        {isAd ? (
          <img className="plexo-logo" src={userHasScrolled ? PlexoLogo : PlexoLogoWhite} alt="Plexo logo" />
        ) : (
          <Link to="/">
            <img className="plexo-logo" src={PlexoLogo} alt="Plexo logo" />
          </Link>
        )}
        {isMobile ? <NavbarMobile /> : <NavbarDesktop />}
      </Box>
    </Box>
  );
}

export default Header;
