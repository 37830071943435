import { SxProps, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import DOMPurify from 'dompurify';
import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { Location, Navigate, useLocation } from 'react-router-dom';
import typImageMobile from '../assets/img/img-typ-xs@2x.png';
import typImageDesktop from '../assets/img/img-typ@2x-desktop.png';
import CustomSnackbar from '../components/common/CustomSnackbar';
import Seo from '../components/common/Seo';
import ViewBanner from '../components/common/ViewBanner';
import EbookHeader from '../components/EbookHeader';
import { config } from '../config/config';
import colors from '../data/colors';
import sendEbookMail from '../services/mailServicesEbook';
import { ContactFormAdsValues } from '../types/adsForm.types';

const thankYou: string = 'thank-you';
const viewBannerThankYou: string = `${thankYou}.viewbanner`;
const contact: string = 'contact';
const viewBannerContact: string = `${contact}.viewbanner`;
const form: string = `${viewBannerContact}.form`;
const errorMessage: string = `${form}.error-messages`;
const successMessage: string = `${form}.success-resend-message`;

const ebookContainerStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: { md: 'calc(100vh - 80px)' },
};

const ebookStyles: SxProps = {
  mt: { md: '-6rem' },
  '& h1': { maxWidth: { xs: '97%', md: '70%' } },
  '& p': { maxWidth: { xs: '97%', md: '79%' } },
  '& a': { color: colors.teal.regular },
};

const ebookFooterStyles: SxProps = {
  backgroundColor: colors.blue,
  color: colors.white,
  height: '80px',
  paddingX: '10%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: { xs: 'center', md: 'start' },
};

const imgStyle: SxProps = {
  width: { xs: '140%', md: '180%' },
  maxHeight: { md: '100vh' },
  mt: { xs: '-6em' },
  mb: { xs: '-5rem' },
  position: 'relative',
  left: { xs: '-0.6rem', md: '5rem' },
  top: { md: '3.5rem' },
};

interface State extends ContactFormAdsValues {
  fromContact?: boolean;
  fromAd?: boolean;
  fromEbook?: boolean;
}

const ThankYou = () => {
  const location: Location = useLocation();
  const locationState = location.state as State;
  const theme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('md'));
  const isFromEbook: boolean | undefined = locationState?.fromEbook;
  const year: number = new Date().getFullYear();
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const { t } = useTranslation();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const toggleSnackbar = () => setOpenSnackbar((prevState: boolean) => !prevState);

  const handleSubmit = async () => {
    const captchaToken = await captchaRef.current?.executeAsync();

    if (captchaToken && locationState) {
      const subject: string = `${t(`${form}.subject-start`)} - ${locationState.fullname}`;
      await sendEbookMail({ ...locationState, subject, captchaToken });
      setIsSuccess(true);
    }

    captchaRef.current?.reset();
    setOpenSnackbar(true);
  };

  return !locationState?.fromContact && !locationState?.fromAd && !locationState?.fromEbook ? (
    <Navigate to="/" />
  ) : (
    <>
      <Seo title={`${thankYou}.meta.title`} />
      {isFromEbook ? (
        <>
          <EbookHeader />
          <Box sx={ebookContainerStyles}>
            <ViewBanner
              title="ebook-ad.thank-you.title"
              paragraphWithButton={
                <div className="container-text-button">
                  <span
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('ebook-ad.thank-you.text-1')) }}
                  />
                  <button
                    onClick={handleSubmit}
                    className="text-format"
                    type="button"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('ebook-ad.thank-you.text-2')) }}
                  />
                </div>
              }
              paragraph="ebook-ad.thank-you.text-3"
              image={{
                src: isMobile ? typImageMobile : typImageDesktop,
                alt: `${viewBannerThankYou}.img-alt`,
                sx: imgStyle,
              }}
              viewBannerSx={{
                root: ebookStyles,
                secondContainer: { display: 'flex', justifyContent: 'center' },
              }}
            />
            <Typography sx={ebookFooterStyles}>© {year} Plexo</Typography>
          </Box>
        </>
      ) : (
        <ViewBanner
          title={`${viewBannerThankYou}.title`}
          paragraph={`${viewBannerThankYou}.text`}
          buttonPrimary={{
            to: locationState?.fromAd ? '/ads/pasarela' : '/',
            content: `${viewBannerThankYou}.button-primary`,
          }}
          image={{
            src: isMobile ? typImageMobile : typImageDesktop,
            alt: `${viewBannerThankYou}.img-alt`,
            sx: imgStyle,
          }}
          viewBannerSx={{
            root: { mt: { md: '-5rem' } },
            secondContainer: { display: 'flex', justifyContent: 'center' },
          }}
        />
      )}
      <ReCAPTCHA ref={captchaRef} id="captcha" sitekey={config.captchaAdsKey!} size="invisible" />
      <CustomSnackbar
        open={openSnackbar}
        handleClose={toggleSnackbar}
        variant={isSuccess ? 'success' : 'error'}
        message={t(
          isSuccess ? successMessage : !captchaRef ? `${errorMessage}.recaptcha` : `${errorMessage}.request`,
        )}
      />
    </>
  );
};

export default ThankYou;
