import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import Cedu from '../../../assets/img/cedu-logo@2x.png';
import Logo from '../../../assets/img/logo-plexo-white@2x.png';
import { plexoProducts } from '../../../data/products';
import { SocialNetwork, socialNetworks } from '../../../data/socialNetworks';
import { PlexoUrl } from '../../../data/urls';
import { Product } from '../../../types/products.types';

type State = { fromAd?: boolean; fromEbook?: boolean } | null;

function Footer() {
  const { t } = useTranslation();
  const year: number = new Date().getFullYear();
  const location = useLocation();
  const locationState = location.state as State;
  const notFound: boolean = location.pathname === '/404';
  const isAd: boolean = location.pathname.includes('ads');
  const isFromAd: boolean | undefined = locationState?.fromAd;
  const isFromEbook: boolean | undefined = locationState?.fromEbook;

  return (
    <>
      {!isAd && !isFromAd && !isFromEbook && (
        <footer id="footer" className={notFound ? 'footer-container-404' : 'footer-container'}>
          <Box className="section">
            {!notFound && (
              <Grid container columnSpacing={0} className="footer-content">
                <Grid item xs={12} lg={4} className="plexo-container">
                  <img className="plexo-logo" src={Logo} alt="Plexo logo" />
                  <span
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('footer-text')) }}
                    className="plexo-text"
                  />
                  <div className="media-container">
                    {socialNetworks.map((socialNetwork: SocialNetwork) => (
                      <Box
                        key={socialNetwork.alt}
                        component="a"
                        href={socialNetwork.href}
                        target="_blank"
                        rel="noreferrer"
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        <img src={socialNetwork.src} alt={socialNetwork.alt} />
                      </Box>
                    ))}
                  </div>
                </Grid>
                <Grid item lg={4} className="solutions-container">
                  <h5>{t('header-menu-item-1')}</h5>
                  {plexoProducts.map((product: Product) => (
                    <Link to={product.url} key={product.title}>
                      {t(product.shortTitle)}
                    </Link>
                  ))}
                </Grid>
                <Grid item lg={4} className="contact-container">
                  <h5>{t('header-menu-item-4')}</h5>
                  <p>{t('footer-contact-address')}</p>
                  <p>(+598) 97 970 481</p>
                  <p>(+598) 97 222 241</p>
                  <p>{t('footer-contact-hours')}</p>
                  <a
                    href={PlexoUrl.CALENDLY}
                    className="plexo-button primary-button"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('contact-button')}
                  </a>
                </Grid>
              </Grid>
            )}
            <Grid container columnSpacing={-2}>
              <Grid item xs={12} lg={6}>
                <div className="cedu-copyright-container">
                  <div className="copyright">© {year} Plexo</div>
                  <div className="cedu">
                    {t('footer-member-text')}
                    <img src={Cedu} alt="Cedu logo" />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} lg={6} className="legacy-container">
                <Link to="/faqs">{t('footer-faqs')}</Link>
                {/* Hidden until document is ready */}
                <a href="/" download className="terms-conditions" style={{ display: 'none' }}>
                  {t('footer-terms-conditions')}
                </a>
              </Grid>
            </Grid>
          </Box>
        </footer>
      )}
    </>
  );
}

export default Footer;
