import { Box, Grid } from '@mui/material';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BenefitsDesktop from '../../../assets/img/img-home-parala@2x.png';
import BenefitsMobile from '../../../assets/img/img-home-pasarela@2x.png';
import ListItems from '../ListItems/ListItems';

function Benefits() {
  const { t } = useTranslation();

  return (
    <Box id="benefits" className="section">
      <Grid container columnSpacing={4} className="section-container">
        <Grid item xs={12} md={6} className="text-container">
          <h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('home-benefits-title')) }} />
          <ListItems
            texts={[
              'home-benefits-item-1',
              'home-benefits-item-2',
              'home-benefits-item-3',
              'home-benefits-item-4',
            ]}
          />
          <div className="button-container">
            <Link to="/contacto" className="plexo-button primary-button">
              {t('home-get-started-button')}
            </Link>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className="img-container">
          <img src={BenefitsDesktop} alt="Plexo benefits" className="desktop-image" />
          <img src={BenefitsMobile} alt="Plexo benefits mobile" className="mobile-image" />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Benefits;
