import { Grid, Stack, SxProps } from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Arrow from '../../assets/img/complete-arrow@2x.png';
import RadialGradient from '../../assets/img/radial-gradient-1.png';
import { Logo } from '../../types/logos.types';

interface CustomLogoGridProps {
  logos: Logo[];
  itemsPerRow: number[];
  colSpacing?: ResponsiveStyleValue<number | string>;
  rowSpacing?: ResponsiveStyleValue<number | string>;
  sx?: LogoGridSx;
  linkTo?: { pathname: string; hash: string };
}

interface LogoGridSx {
  root?: SxProps;
  item?: SxProps;
}

const gridStyle: SxProps = {
  backgroundImage: `url(${RadialGradient})`,
  backgroundSize: '110%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  height: '100%',
  display: { xs: 'none', md: 'flex' },
  alignItems: 'center',
  '& img': { width: '76px', height: '76px', borderRadius: '50%' },
};

function CustomLogoGrid({
  logos,
  itemsPerRow,
  colSpacing = 3,
  rowSpacing,
  linkTo,
}: CustomLogoGridProps): JSX.Element {
  const { t } = useTranslation();

  const renderRows = () => {
    let logoIndex = 0;
    return itemsPerRow.map((itemsInRow, rowIndex) => {
      const rowItems = logos
        .slice(logoIndex, logoIndex + itemsInRow)
        .map((logo) => <img key={`${logo.title}`} src={logo.img} alt={t(logo.title)} />);
      logoIndex += itemsInRow;

      return (
        <Stack key={rowIndex} flex={1} gap={colSpacing} direction="row" mb={rowSpacing}>
          {rowItems}
        </Stack>
      );
    });
  };

  return (
    <Stack direction="column" sx={gridStyle}>
      {renderRows()}
      {linkTo && (
        <Grid item md={6} className="button-container">
          <Link to={{ pathname: '/productos/pasarela', hash: '#banner-2' }} className="button">
            {t('payment-methods-button')}
            <img src={Arrow} alt="Go to gateway solutions" />
          </Link>
        </Grid>
      )}
    </Stack>
  );
}

export default CustomLogoGrid;
