import { Box, Theme, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PlexoLogoWhite from '../assets/img/logo-plexo-white@2x.png';
import PlexoLogo from '../assets/img/plexo-logo-color@2x.png';
import { PlexoUrl } from '../data/urls';

function EbookHeader() {
  const { t } = useTranslation();
  const theme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box className="nav-bar-ebook">
      <Box className="nav-bar-container">
        {isMobile ? (
          <img src={PlexoLogoWhite} alt="Plexo Logo" />
        ) : (
          <>
            <img src={PlexoLogo} alt="Plexo Logo" />
            <a
              href={PlexoUrl.CALENDLY}
              className="plexo-button secondary-button"
              target="_blank"
              rel="noreferrer"
            >
              {t('ebook-ad.contact-button')}
            </a>
          </>
        )}
      </Box>
    </Box>
  );
}

export default EbookHeader;
