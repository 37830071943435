import { Accordion, AccordionDetails, AccordionSummary, SxProps, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import colors from '../data/colors';
import { Faq } from '../types/products.types';
import MinusSquare from './common/icons/MinusSquare';
import PlusSquare from './common/icons/PlusSquare';

const accordionStyle: SxProps = {
  bgcolor: 'unset',
  '&::before': {
    bgcolor: colors.skyblue.regular,
    transition: 'unset',
  },
  '& .MuiButtonBase-root': { padding: '8px 0' },
};

const accordionSummaryStyle: SxProps = {
  '&.Mui-expanded': {
    borderTop: '1px solid',
    borderTopColor: colors.skyblue.regular,
  },
  '&.Mui-expanded h3': { color: colors.teal.light },
};

const accordionDetailsStyle: SxProps = { px: '0' };

const titleStyle: SxProps = {
  color: colors.blue,
  fontSize: { sm: '1.125rem' },
  fontFamily: 'Fira Sans',
  marginRight: '12px',
};

const textStyle: SxProps = {
  color: colors.blue,
  fontSize: { sm: '1rem' },
  fontFamily: 'Fira Sans',
  '& p, ul': { mb: '10px' },
  '& a ': { color: colors.blue },
  ul: { listStylePosition: 'inside' },
};

interface FaqAccordionProps {
  faq: Faq;
}

function FaqAccordion({ faq }: FaqAccordionProps) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(false);

  const toggleExpanded = () => setExpanded((preValue) => !preValue);

  const question = useMemo(() => t(faq.question), [faq.question, t]);
  const answer = useMemo(() => t(faq.answer), [faq.answer, t]);

  return (
    <Accordion disableGutters elevation={0} sx={accordionStyle} expanded={expanded} onChange={toggleExpanded}>
      <AccordionSummary
        expandIcon={expanded ? <MinusSquare /> : <PlusSquare />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={accordionSummaryStyle}
      >
        <Typography variant="h3" sx={titleStyle}>
          {question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={accordionDetailsStyle}>
        <Typography
          variant="body1"
          sx={textStyle}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(answer).replace(/href/g, "target='_blank' href"),
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
}

export default FaqAccordion;
