import { Box, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import { TFunction, useTranslation } from 'react-i18next';
import ImageHeroBannerMobile from '../../assets/img/img-banner-donaciones-mobile@2x.png';
import ImageHeroBannerDesktop from '../../assets/img/img-banner-donaciones@2x.png';
import ImageBanner1 from '../../assets/img/img-don-1@2x.png';
import ImageBanner2 from '../../assets/img/img-don-2@2x.png';
import Banner, { BannerProps } from '../../components/common/Banner';
import { ContactProps } from '../../components/common/Contact';
import Seo from '../../components/common/Seo';
import { ViewBannerProps } from '../../components/common/ViewBanner';
import DonationsSection from '../../components/DonationsSection';
import ProductLayout from '../../components/products/ProductLayout';

const donations: string = 'donations';
const viewBanner: string = `${donations}.viewbanner`;
const banner1: string = `${donations}.banner-1`;
const banner2: string = `${donations}.banner-2`;
const contact: string = `${donations}.contact`;

const viewBannerImage: SxProps = {
  width: { xs: '270%', md: '240%' },
  marginBottom: { xs: '-22rem', md: '-28rem' },
  position: 'relative',
  top: { xs: '-12rem', md: '-11rem' },
  left: { xs: '4rem', md: '8rem' },
};

const viewBannerProps = (isMobile: boolean): ViewBannerProps => ({
  breadcrumbs: { goToProducts: true, current: `${viewBanner}.breadcrumb-current` },
  title: `${viewBanner}.title`,
  paragraph: `${viewBanner}.text`,
  buttonPrimary: { to: '/contacto', content: `${viewBanner}.button-primary` },
  arrowIconTo: '#banner-1',
  image: {
    src: isMobile ? ImageHeroBannerMobile : ImageHeroBannerDesktop,
    alt: `${viewBanner}.img-alt`,
    sx: viewBannerImage,
  },
  smallTitle: true,
  viewBannerSx: { secondContainer: { display: 'flex', justifyContent: 'center' } },
});

const layoutBannerImgStyle: SxProps = {
  width: { xs: '170%', md: '145%' },
  marginTop: { xs: '-5rem', md: '0' },
  marginBottom: { xs: '-2rem', md: '0' },
  marginLeft: { xs: '3rem', md: '0' },
};

const layoutReverseBannerProps = (t: TFunction<'translation', undefined>): BannerProps => ({
  title: `${banner1}.title`,
  imageContainerContent: (
    <Box component="img" src={ImageBanner1} sx={layoutBannerImgStyle} alt={t(`${viewBanner}.img-alt`)} />
  ),
  text: `${banner1}.text`,
  button: { to: '/contacto', content: `${banner1}.button`, sx: { mt: { xs: '1rem' } } },
  bannerSx: { wrapper: { mb: { md: '-12rem' } } },
  id: 'banner-1',
});

const banner2ImgStyle: SxProps = {
  width: { xs: '180%', md: '150%' },
  marginTop: { xs: '-5rem', md: '0' },
  marginBottom: { xs: '-2rem', md: '0' },
  marginLeft: { xs: '-3rem', md: '0' },
};

const banner2props = (t: TFunction<'translation', undefined>): BannerProps => ({
  title: `${banner2}.title`,
  text: `${banner2}.text`,
  button: {
    to: '/contacto',
    content: `${banner2}.button`,
    sx: { mt: { xs: '25px' } },
  },
  imageContainerContent: (
    <Box component="img" src={ImageBanner2} sx={banner2ImgStyle} alt={t(`${banner2}.img-alt`)} />
  ),
  bannerSx: { wrapper: { mb: { md: '3rem' } } },
});

const contactProps: ContactProps = { title: `${contact}.title` };

const Donations = () => {
  const { t } = useTranslation();
  const theme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Seo meta={`${donations}.meta`} />
      <ProductLayout
        viewBannerProps={viewBannerProps(isMobile)}
        reverseBannerProps={layoutReverseBannerProps(t)}
        contactProps={contactProps}
      >
        <Banner {...banner2props(t)} />
        <DonationsSection />
      </ProductLayout>
    </>
  );
};
export default Donations;
