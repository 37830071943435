import { Box, Button, SxProps } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useRef, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { config } from '../config/config';
import colors from '../data/colors';
import schemas from '../data/schemas';
import sendEbookMail from '../services/mailServicesEbook';
import { ContactFormAdsValues } from '../types/adsForm.types';
import CustomSnackbar from './common/CustomSnackbar';
import FormTextField from './common/form/FormTextField';

const contact: string = 'contact';
const viewBanner: string = `${contact}.viewbanner`;
const form: string = `${viewBanner}.form`;
const field: string = `${form}.field`;
const errorMessage: string = `${form}.error-messages`;

const buttonStyles: SxProps = {
  width: '100%',
  py: '12px',
  mt: '1rem',
  background: colors.teal.dark,
  '&:hover': { bgcolor: colors.teal.resourcesCard },
  border: 'none',
};

function EbookForm() {
  const { t } = useTranslation();
  const navigate: NavigateFunction = useNavigate();
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const toggleSnackbar = () => setOpenSnackbar((prevState: boolean) => !prevState);

  const handleSubmit = async (values: ContactFormAdsValues) => {
    const captchaToken = await captchaRef.current?.executeAsync();

    ReactPixel.fbq('track', 'Lead');

    if (captchaToken) {
      const subject: string = `${t(`${form}.subject-start`)} - ${values.fullname}`;

      const success: boolean = await sendEbookMail({ ...values, subject, captchaToken });

      if (success) {
        return navigate('/gracias', { state: { fromEbook: true, ...values } });
      } else {
        captchaRef.current?.reset();
      }
    }
    setOpenSnackbar(true);
  };

  return (
    <>
      <Box id="ads-form">
        <Formik
          initialValues={{ fullname: '', company: '', email: '', phone: '' }}
          onSubmit={handleSubmit}
          validationSchema={schemas.ContactEBookSchema}
        >
          <Form className="form-container">
            <Field label={`${field}-fullname-label`} name="fullname" component={FormTextField} />
            <Field label={`${field}-email-label`} name="email" component={FormTextField} />
            <Field label={`${field}-company-label-optional`} name="company" component={FormTextField} />
            <ReCAPTCHA ref={captchaRef} id="captcha" sitekey={config.captchaAdsKey!} size="invisible" />
            <Button type="submit" variant="contained" sx={buttonStyles}>
              {t(`${form}.submit-ebook`)}
            </Button>
          </Form>
        </Formik>
      </Box>
      <CustomSnackbar
        open={openSnackbar}
        handleClose={toggleSnackbar}
        variant="error"
        message={t(!captchaRef ? `${errorMessage}.recaptcha` : `${errorMessage}.request`)}
      />
    </>
  );
}

export default EbookForm;
