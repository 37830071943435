import { Box, Grid, SxProps, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SociosCorazón from '../assets/img/logo-socios-corazon@2x.png';
import Teleton from '../assets/img/logo-teleton@2x.png';
import colors from '../data/colors';
import { PlexoUrl } from '../data/urls';

const donationsFrame1: string = 'donations-section.frame-1';
const donationsFrame2: string = 'donations-section.frame-2';

const titleStyle: SxProps<Theme> = { mb: '3.5rem', textAlign: 'center' };

const container: SxProps<Theme> = {
  backgroundColor: colors.white,
  padding: { xs: '2rem', md: '4rem 5rem' },
  borderRadius: '15px',
  marginBottom: '10rem',
  position: 'relative',
  zIndex: 2,
  '& img': { width: '50%', marginBottom: '25px' },
  '& h3': { marginBottom: '20px' },
};

const overlayStyle: SxProps = {
  background: `transparent radial-gradient(closest-side at 66% 59%, ${colors.teal.regular} 0%, ${colors.skyblue.dark} 66%, ${colors.skyblue.regular} 100%) 0% 0% no-repeat padding-box`,
  opacity: 0.14,
  filter: 'blur(50px)',
  position: 'absolute',
  inset: 0,
};

const frame1: SxProps<Theme> = {
  paddingRight: { md: '100px' },
  paddingBottom: { xs: '30px' },
  borderRight: { xs: 'unset', md: `1px solid ${colors.lightGreen}` },
  borderBottom: { xs: `1px solid ${colors.lightGreen}`, md: 'unset' },
};

const frame2: SxProps<Theme> = {
  paddingLeft: { md: '100px' },
  paddingTop: { xs: '60px', md: '0' },
};

function DonationsSection() {
  const { t } = useTranslation();

  return (
    <Box component="section" position="relative">
      <Box sx={overlayStyle} />
      <Box className="section">
        <Typography variant="h2" className="heading-primary" sx={titleStyle}>
          {t('donations-section.title')}
        </Typography>
        <Box sx={container}>
          <Grid container>
            <Grid item xs={12} md={6} sx={frame1}>
              <img src={Teleton} alt="Teleton" />
              <Typography variant="h3" className="heading-primary">
                {t(`${donationsFrame1}.sub-title`)}
              </Typography>
              <Typography variant="body1" className="heading-primary" sx={{ mb: '1.5rem' }}>
                {t(`${donationsFrame1}.text-1`)}
              </Typography>
              <Typography variant="body1" className="heading-primary">
                {t(`${donationsFrame1}.text-2`)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={frame2}>
              <img src={SociosCorazón} alt="Socios de Corazón" />
              <Typography variant="h3" className="heading-primary">
                {t(`${donationsFrame2}.sub-title`)}
              </Typography>
              <Typography variant="body1" className="heading-primary" sx={{ mb: '1.5rem' }}>
                {t(`${donationsFrame2}.text-1`)}
              </Typography>
              <Typography component="span" className="heading-primary">
                {t(`${donationsFrame2}.text-2`)}
              </Typography>
              <a href={PlexoUrl.PX_DONATIONS} target="_blank" rel="noreferrer" className="link">
                {t(`${donationsFrame2}.link`)}
              </a>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default DonationsSection;
