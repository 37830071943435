import colors from '../../../data/colors';
import { SvgIcon } from '../../../types/svgIcon.types';

const FileDownload = ({ color }: SvgIcon) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18.928" height="25.237" viewBox="0 0 18.928 25.237">
    <path
      id="file-download"
      d="M18.233,4.83,14.1.7a2.366,2.366,0,0,0-1.67-.7H2.365A2.374,2.374,0,0,0,0,2.37v20.5a2.366,2.366,0,0,0,2.365,2.365h14.2a2.366,2.366,0,0,0,2.365-2.365V6.5a2.377,2.377,0,0,0-.7-1.675ZM12.62,1.606a.775.775,0,0,1,.365.207l4.135,4.135a.775.775,0,0,1,.207.365H12.62Zm4.731,21.265a.791.791,0,0,1-.789.789H2.365a.791.791,0,0,1-.789-.789V2.37a.8.8,0,0,1,.789-.793h8.677v5.13a1.177,1.177,0,0,0,1.183,1.18h5.125Zm-7.1-12.225a.394.394,0,0,0-.394-.394H9.07a.394.394,0,0,0-.394.394v4.339H6.08a1.328,1.328,0,0,0-1.232.824,1.4,1.4,0,0,0,.268,1.519l3.358,3.538a1.358,1.358,0,0,0,1.981,0l3.358-3.537a1.4,1.4,0,0,0,.268-1.52,1.328,1.328,0,0,0-1.232-.824H10.253Zm2.112,5.916-2.9,3.056-2.9-3.056Z"
      fill={color || colors.white}
    />
  </svg>
);

export default FileDownload;
