import { Faq } from '../types/products.types';

const eCommerce: string = 'e-commerce';
const plexoLinks: string = 'plexo-links';
const subscriptions: string = 'subscriptions';
const donations: string = 'donations';
const commonExpenses: string = 'common-expenses';
const plexoInvoice: string = 'plexo-invoice';

const generateFaqsArray = (productType: string, length: number): Faq[] => {
  const result: Faq[] = [];
  for (let index: number = 0; index < length; index++) {
    result.push({
      question: `faqs.${productType}.questions.question-${index + 1}`,
      answer: `faqs.${productType}.answers.answer-${index + 1}`,
    });
  }
  return result;
};

export const commerceFaqs: Faq[] = generateFaqsArray(eCommerce, 17);

export const plexoLinksFaqs: Faq[] = generateFaqsArray(plexoLinks, 8);

export const subscriptionsFaqs: Faq[] = generateFaqsArray(subscriptions, 4);

export const donationsFaqs: Faq[] = generateFaqsArray(donations, 4);

export const commonExpensesFaqs: Faq[] = generateFaqsArray(commonExpenses, 5);

export const plexoInvoiceFaqs: Faq[] = generateFaqsArray(plexoInvoice, 3);
