import { SxProps, Tab, Tabs, Theme } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import colors from '../data/colors';
import { plexoProducts } from '../data/products';
import { Product } from '../types/products.types';

const tabsStyle: SxProps<Theme> = { '& .MuiTabs-indicator': { display: 'none' } };

const tabStyle: SxProps<Theme> = {
  backgroundColor: colors.white,
  borderRadius: '12px',
  marginBottom: '10px',
  color: colors.blue,
  marginRight: '70px',
  textTransform: 'capitalize',
  fontSize: '0.875rem',
  fontFamily: 'Ubuntu',
  alignItems: 'flex-start',
  py: '2px',
  pl: '8px',
  '& img': { width: '60px' },
  '&.Mui-selected': {
    color: colors.teal.lightest,
    bgcolor: colors.blue,
  },
};

interface FaqsMenuDesktopProps {
  tabIndex: number;
  setTabIndex: Dispatch<SetStateAction<number>>;
}

function FaqsMenuDesktop({ tabIndex, setTabIndex }: FaqsMenuDesktopProps) {
  const { t } = useTranslation();

  const handleDesktopChange = (event: React.SyntheticEvent, newValue: number) => setTabIndex(newValue);

  return (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={tabIndex}
      onChange={handleDesktopChange}
      aria-label="Plexo products"
      sx={tabsStyle}
    >
      {plexoProducts.map((product: Product, index: number) => (
        <Tab
          key={product.title}
          label={
            <div className="faqs-items">
              <img src={product.icon} alt={t(product.shortTitle)} />
              {t(product.shortTitle)}
            </div>
          }
          id={`vertical-tab-${index}`}
          aria-controls={`vertical-tabpanel-${index}`}
          sx={tabStyle}
        />
      ))}
    </Tabs>
  );
}

export default FaqsMenuDesktop;
