export const config = {
  env: process.env.REACT_APP_ENV_NAME,
  backendUrl: process.env.REACT_APP_BACKEND_URL,
  backendUrlAds: process.env.REACT_APP_BACKEND_URL_ADS,
  backendUrlEbook: process.env.REACT_APP_BACKEND_URL_EBOOK,
  publicUrl: process.env.PUBLIC_URL,
  captchaKey: process.env.REACT_APP_CAPTCHA_SITE_KEY,
  captchaAdsKey: process.env.REACT_APP_CAPTCHA_SITE_KEY_ADS,
  trackingId: process.env.REACT_APP_TRACKING_ID,
  pixelId: process.env.REACT_APP_PIXEL_ID,
};
