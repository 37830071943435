import { Box, Button, SxProps } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import ContactBannerImage from '../assets/img/img-contact@2x.png';
import ContactNumbers from '../components/ContactNumbers';
import CustomSnackbar from '../components/common/CustomSnackbar';
import Seo from '../components/common/Seo';
import ViewBanner from '../components/common/ViewBanner';
import FormTextField from '../components/common/form/FormTextField';
import { config } from '../config/config';
import schemas from '../data/schemas';
import mailsService from '../services/mailsService';

const rootStyles: SxProps = { mb: { xs: '2.5rem', md: '6rem' } };

const secondContainerStyles: SxProps = {
  display: { md: 'inherit' },
  height: 'auto',
};

const inputWrapperStyles: SxProps = {
  display: 'flex',
  gap: '1rem',
  marginBottom: '1rem',
  flexDirection: { xs: 'column', md: 'row' },
};

const imageStyles: SxProps = {
  height: '150%',
  position: 'absolute',
  left: '0',
  top: '-20%',
  opacity: '65%',
  display: { xs: 'none', md: 'unset' },
};

const textareaStyles: SxProps = {
  formControl: {
    mt: '1rem',
    mb: { xs: '2.5rem', md: '3rem' },
  },
};

const buttonStyles: SxProps = {
  width: { xs: '100%', md: 'fit-content' },
  px: { md: '3.5rem' },
  mt: '2rem',
};

export interface ContactFormValues {
  fullname: string;
  email: string;
  message: string;
}

const contact: string = 'contact';
const viewBanner: string = `${contact}.viewbanner`;
const form: string = `${viewBanner}.form`;
const field: string = `${form}.field`;
const errorMessage: string = `${form}.error-messages`;

const Contact = () => {
  const { t } = useTranslation();
  const navigate: NavigateFunction = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [captcha, setCaptcha] = useState<string | null>('');

  const toggleSnackbar = () => setOpenSnackbar((prevState: boolean) => !prevState);

  const handleSubmit = async (values: ContactFormValues) => {
    if (captcha) {
      const subject: string = `${t(`${form}.subject-start`)} - ${values.fullname}`;

      const success = await mailsService({ ...values, subject, captcha: captcha! });

      if (success) {
        return navigate('/gracias', { state: { fromContact: true } });
      }
    }
    setOpenSnackbar(true);
  };

  return (
    <>
      <Seo meta={`${contact}.meta`} />
      <ViewBanner
        title={`${viewBanner}.title`}
        paragraph={`${viewBanner}.text`}
        breadcrumbs={{ goToProducts: false, current: `${viewBanner}.breadcrumb-current` }}
        viewBannerSx={{ root: rootStyles, secondContainer: secondContainerStyles }}
        image={{
          alt: 'Plexo HQ location',
          src: ContactBannerImage,
          sx: imageStyles,
        }}
        children2={<ContactNumbers />}
      >
        <Formik
          initialValues={{ fullname: '', email: '', message: '' }}
          onSubmit={handleSubmit}
          validationSchema={schemas.ContactSchema}
        >
          <Form>
            <Box sx={inputWrapperStyles}>
              <Field label={`${field}-fullname-label`} name="fullname" component={FormTextField} />
              <Field label={`${field}-email-label`} name="email" component={FormTextField} />
            </Box>
            <Field
              label={`${field}-message-label`}
              name="message"
              component={FormTextField}
              type="textarea"
              sx={textareaStyles}
            />
            <ReCAPTCHA
              id="captcha"
              sitekey={config.captchaKey!}
              onChange={(value: string | null) => setCaptcha(value)}
            />
            <Button type="submit" variant="contained" color="info" sx={buttonStyles}>
              {t(`${form}.submit`)}
            </Button>
          </Form>
        </Formik>
      </ViewBanner>
      <CustomSnackbar
        open={openSnackbar}
        handleClose={toggleSnackbar}
        variant="error"
        message={t(!captcha ? `${errorMessage}.recaptcha` : `${errorMessage}.request`)}
      />
    </>
  );
};

export default Contact;
