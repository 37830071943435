import { Box, Divider, SxProps, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import colors from '../data/colors';

const sectionContainer: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: { xs: 'flex-start', md: 'flex-end' },
};

const contactNumbers: SxProps = {
  borderLeft: { xs: 'unset', md: `3px solid ${colors.skyblue.transparent}` },
  paddingTop: '1rem',
  paddingLeft: { xs: 'unset', md: '5.3rem' },
  marginLeft: { xs: 'unset', md: '5.3rem' },
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
  '& p': { fontFamily: 'Ubuntu', fontSize: '1rem' },
  alignItems: { xs: 'center', md: 'unset' },
  marginBottom: { xs: '2.5rem', md: 'unset' },
};

const contactNumbersTitle: SxProps = {
  fontWeight: '700',
  fontFamily: 'Ubuntu',
  fontSize: { xs: '1.4375rem', md: '1.5625rem' },
  textAlign: { xs: 'center', md: 'start' },
};

const contactNumbersSubTitle: SxProps = {
  color: colors.darkGreen,
  fontWeight: '500',
  fontSize: '1.125rem',
};

const sectionDivider: SxProps = {
  backgroundColor: colors.skyblue.lightTransparent,
  width: 'auto',
  marginX: '-3rem',
  marginY: '2.5rem',
};

const numbersDivider: SxProps = {
  backgroundColor: colors.skyblue.lightTransparent,
  width: '60%',
};

function ContactNumbers() {
  const { t } = useTranslation();
  const theme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('md'));

  const contact: string = 'contact';
  const viewBanner: string = `${contact}.viewbanner`;
  const contactNumbersText: string = `${viewBanner}.contact-numbers`;

  return (
    <>
      <Box sx={sectionContainer}>
        {isMobile && <Divider sx={sectionDivider} />}
        <Box sx={contactNumbers}>
          <Typography variant="h5" sx={contactNumbersTitle}>
            {t(`${contactNumbersText}.title`)}
          </Typography>
          <Box>
            <Typography variant="body1" sx={contactNumbersSubTitle}>
              {t(`${contactNumbersText}.subtitle-1`)}
            </Typography>
            <Typography variant="body1">(+598) 95 501 741</Typography>
          </Box>
          {isMobile && <Divider sx={numbersDivider} />}
          <Box>
            <Typography variant="body1" sx={contactNumbersSubTitle}>
              {t(`${contactNumbersText}.subtitle-2`)}
            </Typography>
            <Typography variant="body1">(+598) 97 970 481</Typography>
            <Typography variant="body1">(+598) 97 222 241</Typography>
            <Typography variant="body1">(+598) 97 885 323</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ContactNumbers;
