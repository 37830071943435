import { Box, Chip, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import { TFunction, useTranslation } from 'react-i18next';
import ImageHeroBanner from '../../assets/img/img-banner-invoice@2x.png';
import ImageBanner1Mobile from '../../assets/img/img-invoice-1-mobile@2x.png';
import ImageBanner1Desktop from '../../assets/img/img-invoice-1@2x.png';
import { ReactComponent as ListItemLogo } from '../../assets/svg/listItem.svg';
import { BannerProps } from '../../components/common/Banner';
import Seo from '../../components/common/Seo';
import VerticalSteps, { VerticalStepsProps } from '../../components/common/VerticalSteps';
import { ViewBannerProps } from '../../components/common/ViewBanner';
import ProductLayout from '../../components/products/ProductLayout';
import colors from '../../data/colors';

const plexoInvoice: string = 'px-invoice';
const stepper: string = `${plexoInvoice}.stepper`;
const viewBanner: string = `${plexoInvoice}.viewbanner`;
const banner1: string = `${plexoInvoice}.banner-1`;
const contact: string = `${plexoInvoice}.contact`;

const viewBannerImage: SxProps = {
  width: { xs: '230%', md: '200%' },
  mb: { xs: '-18rem', md: '-26rem' },
  position: 'relative',
  top: { xs: '-10rem', md: '-8rem' },
  left: { xs: '1rem', md: '0' },
};

const viewBannerProps: ViewBannerProps = {
  breadcrumbs: { goToProducts: true, current: `${viewBanner}.breadcrumb-current` },
  title: `${viewBanner}.title`,
  paragraph: `${viewBanner}.text`,
  buttonPrimary: { to: '/contacto', content: `${viewBanner}.button-primary` },
  arrowIconTo: '#banner-1',
  image: {
    src: ImageHeroBanner,
    alt: `${viewBanner}.img-alt`,
    sx: viewBannerImage,
  },
  viewBannerSx: {
    root: { mb: { xs: '2.5rem', md: '2rem' } },
    secondContainer: { display: 'flex', justifyContent: 'center' },
  },
};

const layoutBannerImgStyle: SxProps = {
  width: { xs: '220%', md: '140%' },
  position: 'relative',
  top: { xs: '-8%', md: '0' },
  left: { xs: '-60%', md: '-42%' },
};

const bannerImgContainerStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  mt: '2.5rem',
  mb: '7rem',
};

const bannerItemsContainerStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '0.94rem',
  zIndex: '2',
};

const bannerImgStyle: SxProps = { width: '200%', mt: 'clamp(-32rem, -72%, -10rem)' };

const bannerItemsStyle: SxProps = {
  bgcolor: colors.white,
  fontFamily: 'Fira Sans',
  color: colors.blue,
  borderRadius: '0.75rem',
  boxShadow: `0 3px 6px ${colors.grayShadow}`,
  display: 'flex',
  justifyContent: 'center',
  '.MuiChip-icon': { mr: '0' },
};

const bannerItemsMobileStyle: SxProps = {
  ...bannerItemsStyle,
  fontSize: '1rem',
  py: '12%',
};

const bannerItemsDesktopStyle: SxProps = {
  ...bannerItemsStyle,
  fontSize: '0.938rem',
  position: 'absolute',
  minHeight: '6%',
};

const item1Style: SxProps = {
  ...bannerItemsDesktopStyle,
  minWidth: { xs: '40%', md: '30%' },
  top: { md: 'clamp(11rem, 37%, 19rem)' },
  right: { md: 'clamp(11rem, 56%, 21rem)' },
};

const item2Style: SxProps = {
  ...bannerItemsDesktopStyle,
  minWidth: { xs: '40%', md: '35%' },
  top: { md: 'clamp(15rem, 47%, 24rem)' },
  right: { md: 'clamp(15rem, 66%, 28rem)' },
};

const item3Style: SxProps = {
  ...bannerItemsDesktopStyle,
  minWidth: { xs: '40%', md: '35%' },
  top: { md: 'clamp(19rem, 60%, 32rem)' },
  right: { md: 'clamp(11rem, 52%, 30rem)' },
};

const verticalStepProps: VerticalStepsProps = {
  title: `${stepper}.title`,
  stepLabels: [`${stepper}.first`, `${stepper}.second`, `${stepper}.third`, `${stepper}.fourth`],
  verticalStepsSx: {
    root: {
      mt: { xs: '3rem', md: '8rem' },
      mb: { xs: '6rem', md: '9.25rem' },
      '& .MuiStepLabel-label': { width: '60%' },
    },
  },
};

const layoutReverseBannerProps = (
  isMobile: boolean,
  t: TFunction<'translation', undefined>,
): BannerProps => ({
  title: `${banner1}.title`,
  imageContainerContent: isMobile ? (
    <Box sx={bannerImgContainerStyle}>
      <Box sx={bannerItemsContainerStyle}>
        <Chip label={t(`${banner1}.item-1`)} icon={<ListItemLogo />} sx={bannerItemsMobileStyle} />
        <Chip label={t(`${banner1}.item-2`)} icon={<ListItemLogo />} sx={bannerItemsMobileStyle} />
        <Chip label={t(`${banner1}.item-3`)} icon={<ListItemLogo />} sx={bannerItemsMobileStyle} />
      </Box>
      <Box component="img" src={ImageBanner1Mobile} sx={bannerImgStyle} />
    </Box>
  ) : (
    <Box sx={{ position: 'relative' }}>
      <Box
        component="img"
        src={ImageBanner1Desktop}
        sx={layoutBannerImgStyle}
        alt={t(`${banner1}.img-alt`)}
      />
      <Chip label={t(`${banner1}.item-1`)} icon={<ListItemLogo />} sx={item1Style} />
      <Chip label={t(`${banner1}.item-2`)} icon={<ListItemLogo />} sx={item2Style} />
      <Chip label={t(`${banner1}.item-3`)} icon={<ListItemLogo />} sx={item3Style} />
    </Box>
  ),
  text: `${banner1}.text`,
  button: { to: '/contacto', content: 'button-start', sx: { mt: { xs: '1rem' } } },
  bannerSx: { wrapper: { mb: { xs: '-14rem', md: '-11rem' } } },
  id: 'banner-1',
});

const Invoice = () => {
  const { t } = useTranslation();
  const theme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Seo meta={`${plexoInvoice}.meta`} />
      <ProductLayout
        viewBannerProps={viewBannerProps}
        reverseBannerProps={layoutReverseBannerProps(isMobile, t)}
        contactProps={{ title: `${contact}.title` }}
      >
        <VerticalSteps {...verticalStepProps} />
      </ProductLayout>
    </>
  );
};

export default Invoice;
