import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  SxProps,
  Typography,
} from '@mui/material';
import DOMPurify from 'dompurify';
import { KeyboardEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import colors from '../data/colors';
import { File, Plugin } from '../data/plugins';
import ChevronDown from './common/icons/ChevronDown';
import CloudDownload from './common/icons/CloudDownload';
import FileDownload from './common/icons/FileDownload';

const cardStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1.875rem',
};

const imgStyle: SxProps = {
  maxWidth: '114px',
  maxHeight: '114px',
  borderRadius: '50%',
  boxShadow: `0 3px 6px ${colors.blackShadow}`,
};

const dropdownSection: SxProps = { width: { xs: '13.5rem', md: '14rem' } };

const buttonStyle: SxProps = {
  width: '100%',
  justifyContent: 'space-between',
  bgcolor: colors.teal.dark,
  borderColor: colors.teal.dark,
  '&:hover': {
    bgcolor: colors.teal.resourcesCard,
    borderColor: colors.teal.resourcesCard,
  },
};

const menuStyle: SxProps = {
  width: '100%',
  mt: '0.5rem',
  borderRadius: '21px',
  boxShadow: `0 3px 6px ${colors.blueShadow}`,
  bgcolor: 'primary.light',
  px: '1.25rem',
  boxSizing: 'border-box',
};

const menuItemStyle: SxProps = {
  display: 'flex',
  gap: '0.75rem',
  color: 'secondary.main',
  px: 0,
  pt: '0.75rem',
  borderColor: 'secondary.main',
};

const PluginCard = ({ title, img, files }: Plugin) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => setOpen((previousState: boolean) => !previousState);

  const handleClose = (event: Event | SyntheticEvent) =>
    !anchorRef.current?.contains(event.target as HTMLElement) ? setOpen(false) : undefined;

  const handleListKeyDown = (event: KeyboardEvent) => {
    if (['Tab', 'Escape'].includes(event.key)) {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when transition from !open -> open
  const previousOpenRef = useRef(open);

  useEffect(() => {
    if (previousOpenRef.current && !open) {
      anchorRef.current!.focus();
    }

    previousOpenRef.current = open;
  }, [open]);

  return (
    <Grid key={title} item xs={12} md={4}>
      <Box sx={cardStyle}>
        <Box component="img" src={img || ''} sx={imgStyle} />
        <Typography
          variant="h3"
          color="secondary.main"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t(title)) }}
        />
        <Box sx={dropdownSection}>
          <Button
            ref={anchorRef}
            id={`button-${title}`}
            variant="contained"
            color="primary"
            aria-controls={open ? `basic-button-${title}` : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleToggle}
            endIcon={<ChevronDown />}
            sx={buttonStyle}
          >
            {t('resources.plugins.button')}
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
            sx={dropdownSection}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: `left ${placement === 'bottom-start' ? 'top' : 'bottom'}`,
                }}
              >
                <Paper sx={menuStyle}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      {Object.values(files).map((file: File, index: number) => {
                        const isFirstItem: boolean = index === 0;

                        return (
                          <MenuItem
                            component="a"
                            href={file.href}
                            download
                            onClick={handleClose}
                            sx={menuItemStyle}
                            divider={isFirstItem}
                            key={file.filename || index}
                          >
                            {t(
                              isFirstItem
                                ? 'resources.plugins.menu-option-1'
                                : 'resources.plugins.menu-option-2',
                            )}
                            {isFirstItem ? <FileDownload color={colors.blue} /> : <CloudDownload />}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      </Box>
    </Grid>
  );
};

export default PluginCard;
