import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { config } from './config/config';
import { components, palette, typography } from './config/mui-theme';
import Main from './screens/Main';
import ScrollToTop from './screens/ScrollToTop';
import './styles/app.scss';

ReactPixel.init(config.pixelId!);

const tagManagerConfig = { gtmId: config.trackingId || '' };

const tagManagerArgs = {
  dataLayer: { page: window.location.pathname },
  dataLayerName: `${window.location.pathname}DataLayer`,
};

TagManager.initialize(tagManagerConfig);

const theme = createTheme({
  palette,
  typography,
  components,
});

function App() {
  useEffect(() => TagManager.dataLayer(tagManagerArgs), []);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <ScrollToTop />
          <Main />
        </HelmetProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
