import { Box, SxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import colors from '../../data/colors';
import UseCasesMobile from './UseCaseMobile';
import UseCasesDesktop from './UseCasesDesktop';

const useCasesSection: SxProps = {
  backgroundColor: colors.blue,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingY: { xs: '4.5rem', md: '7.5rem' },
  '& h2': { fontSize: { xs: '2.25rem', md: '4.375rem' } },
};

const sectionTitle: SxProps = {
  color: colors.teal.lightest,
  maxWidth: { xs: '70%', md: '50%' },
  textAlign: 'center',
  marginBottom: { xs: '3.8rem', md: '6.3rem' },
};

function UseCasesSection() {
  const { t } = useTranslation();
  const theme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('md'));

  const plexoLinks: string = 'px-links';
  const useCasesSectionTexts: string = `${plexoLinks}.use-cases`;

  return (
    <Box sx={useCasesSection}>
      <Typography
        variant="h2"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t(`${useCasesSectionTexts}.section-title`)) }}
        className="heading-primary"
        sx={sectionTitle}
      />
      {isMobile ? <UseCasesMobile /> : <UseCasesDesktop />}
    </Box>
  );
}

export default UseCasesSection;
