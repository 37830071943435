import {
  Box,
  Button,
  Grid,
  GridDirection,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import DOMPurify from 'dompurify';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { Link } from 'react-router-dom';
import RadialGradient from '../../assets/img/radial-gradient-1.png';
import LogoSlider, { LogoSliderProps } from '../products/LogoSlider';
import ListItems from './ListItems/ListItems';

interface ButtonProps {
  content: string;
  to: string;
  alignLeftMobile?: boolean;
  sx?: SxProps<Theme>;
}

interface BannerSx {
  wrapper?: SystemStyleObject<Theme>;
  grid?: SystemStyleObject<Theme>;
  textContainer?: SystemStyleObject<Theme>;
  imageContainer?: SxProps;
  logoSliderContainer?: SystemStyleObject<Theme>;
  listItems?: SxProps;
}

export interface BannerProps {
  title: string;
  imageContainerContent: JSX.Element;
  subtitle?: string;
  text?: string;
  textSecondary?: string;
  listItems?: string[];
  button?: ButtonProps;
  formButton?: string;
  gridDirection?: GridDirection;
  bannerSx?: BannerSx;
  id?: string;
  color?: 'primary' | 'secondary';
  alignTextLeftMobile?: boolean;
  titleAboveImage?: boolean;
  mobileLogoSlider?: LogoSliderProps;
  customSectionAnchor?: boolean;
}

const section: SystemStyleObject<Theme> = {
  width: { xs: '80%' },
  marginInline: 'auto',
  maxWidth: '1200px',
};

const textContainer: SystemStyleObject<Theme> = {
  width: { xs: '80%', md: 'inherit' },
  marginInline: 'auto',
  placeSelf: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '1.25rem',
  textAlign: { xs: 'center', md: 'left' },
};

const imageContainer: SystemStyleObject<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  height: { md: 'auto' },
};

const logoSliderSection: SystemStyleObject<Theme> = {
  backgroundImage: `url(${RadialGradient})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  display: { xs: 'flex', md: 'none' },
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '2rem',
  height: '300px',
  mt: '-20px',
};

function Banner({
  title,
  subtitle,
  text,
  textSecondary,
  listItems,
  button,
  formButton,
  bannerSx,
  id,
  imageContainerContent,
  alignTextLeftMobile,
  mobileLogoSlider,
  gridDirection = 'row',
  color = 'primary',
  titleAboveImage = false,
  customSectionAnchor = false,
}: BannerProps) {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const isLight: boolean = color === 'primary';
  const textColor: string = isLight ? 'secondary.main' : 'primary.light';
  const theme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('md'));
  const mobileTitleAboveImage: boolean = isMobile && titleAboveImage;

  const titleComponent = (outsideGrid: boolean) => (
    <Typography
      variant="h2"
      color={textColor}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t(title)) }}
      className="heading-primary"
      sx={outsideGrid ? { ...section, textAlign: { xs: 'center', md: 'left' } } : { textAlign: 'inherit' }}
    />
  );

  const buttonComponent = button && (
    <Button
      component={Link}
      to={button.to}
      variant="contained"
      color="primary"
      sx={{
        width: 'fit-content',
        zIndex: '1',
        alignSelf: { xs: button.alignLeftMobile ? 'flex-start' : 'center', md: 'flex-start' },
        ...button.sx,
      }}
    >
      {t(button.content)}
    </Button>
  );

  const textContainerContent = (
    <>
      {!mobileTitleAboveImage && titleComponent(false)}
      {subtitle && (
        <Typography
          variant="body1"
          color="primary.main"
          className="text"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t(subtitle)) }}
        />
      )}
      {text && (
        <Typography
          variant="body1"
          color={textColor}
          className="text"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t(text)) }}
        />
      )}
      {textSecondary && (
        <Typography
          variant="body1"
          color={textColor}
          className="text"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t(textSecondary)) }}
        />
      )}
      {listItems && <ListItems texts={listItems} color={color} sx={bannerSx?.listItems} />}
      {(!mobileLogoSlider || (mobileLogoSlider && !isMobile)) && buttonComponent}
      {formButton && !isMobile && (
        <Box component="a" href={formButton} className="plexo-button primary-button">
          {t('home.contact.btn-primary')}
        </Box>
      )}
    </>
  );

  return (
    <Box
      component="section"
      id={id}
      sx={[bannerSx?.wrapper || false, !isLight && { bgcolor: 'secondary.main' }]}
    >
      {id && <span id={`section-anchor${customSectionAnchor ? '-custom' : ''}`} />}
      {mobileTitleAboveImage && titleComponent(true)}
      <Grid container direction={gridDirection} sx={[section, !!bannerSx?.grid && bannerSx.grid]}>
        <Grid
          item
          xs={12}
          md={6}
          sx={[
            textContainer,
            !!bannerSx?.textContainer && bannerSx.textContainer,
            !!alignTextLeftMobile && { textAlign: { xs: 'left' } },
            !!mobileTitleAboveImage && { order: 2 },
          ]}
        >
          {textContainerContent}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            ...imageContainer,
            ...bannerSx?.imageContainer,
            order: mobileTitleAboveImage ? 1 : undefined,
          }}
        >
          {imageContainerContent}
        </Grid>
      </Grid>
      {mobileLogoSlider && (
        <Box sx={[logoSliderSection, !!bannerSx?.logoSliderContainer && bannerSx?.logoSliderContainer]}>
          <LogoSlider logos={mobileLogoSlider?.logos} settings={mobileLogoSlider?.settings} />
          {buttonComponent}
        </Box>
      )}
    </Box>
  );
}

export default Banner;
