import Magento from '../assets/img/logo-magento@2x.png';
import Prestashop from '../assets/img/logo-prestashop@2x.png';
import Woo from '../assets/img/logo-woo@2x.png';
import MagentoManualPdf from '../assets/plugins/magento/Plexo-Manual-Plugin-Magento.pdf';
import MagentoPlugin from '../assets/plugins/magento/plexo-plugin-magento.zip';
import PrestashopManualPdf from '../assets/plugins/prestashop/Plexo-Manual-Plugin-PrestaShop.pdf';
import PrestashopPlugin from '../assets/plugins/prestashop/plexo-plugin-prestashop.zip';
import WooCommerceManualPdf from '../assets/plugins/woocommerce/Plexo-Manual-Plugin-WooCommerce.pdf';
import WooCommercePlugin from '../assets/plugins/woocommerce/plexo-plugin-woocommerce.zip';

export interface Plugin {
  title: string;
  img: string;
  files: Files;
}

export interface Files {
  manual: File;
  plugin: File;
}

export interface File {
  href: string;
  filename?: string;
}

export const plugins: Plugin[] = [
  {
    title: 'Woo Commerce',
    img: Woo,
    files: {
      manual: { href: WooCommerceManualPdf },
      plugin: { href: WooCommercePlugin },
    },
  },
  {
    title: 'Magento',
    img: Magento,
    files: {
      manual: { href: MagentoManualPdf },
      plugin: { href: MagentoPlugin },
    },
  },
  {
    title: 'PrestaShop',
    img: Prestashop,
    files: {
      manual: { href: PrestashopManualPdf },
      plugin: { href: PrestashopPlugin },
    },
  },
];
