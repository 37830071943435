import { Box, SxProps } from '@mui/material';
import { useEffect, useLayoutEffect } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Location, useLocation } from 'react-router-dom';
import CardIcon from '../../assets/img/icon-tarjeta@2x.png';
import ImageBanner from '../../assets/img/img-home-parala@2x.png';
import RocketIcon from '../../assets/img/rocket@2x.png';
import Banner, { BannerProps } from '../../components/common/Banner';
import ViewBanner, { ViewBannerProps } from '../../components/common/ViewBanner';
import Marquee from '../../components/home/Marquee/Marquee';
import colors from '../../data/colors';
import i18n from '../../i18n/config';

const imageContainerStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const bannerImgStyle: SxProps = {
  width: { xs: '150%', sm: '100%' },
  position: 'relative',
  left: { xs: '-1rem', md: '-4rem' },
};

const buttonToFormStyle: SxProps = {
  display: { md: 'none' },
  zIndex: '1',
  position: 'absolute',
  bottom: { xs: '-4rem', sm: '-3rem' },
};

const viewBannerImage: SxProps = {
  display: { xs: 'none', lg: 'block' },
  height: '20%',
  position: 'absolute',
  bottom: '10rem',
  left: '-2rem',
};

const viewBannerImage1: SxProps = {
  display: { xs: 'none', lg: 'block' },
  height: '20%',
  position: 'absolute',
  bottom: '2rem',
  left: '-8rem',
};

const viewBannerProps = (): ViewBannerProps => ({
  id: 'viewBanner',
  adsTitle: 'gateway-ad.viewbanner.ads-title',
  title: 'gateway-ad.viewbanner.title',
  paragraph: 'gateway-ad.viewbanner.text',
  arrowIconTo: '#banner',
  image: {
    src: RocketIcon,
    alt: 'cohete',
    sx: viewBannerImage,
  },
  image1: {
    src: CardIcon,
    alt: 'tarjeta de crédito',
    sx: viewBannerImage1,
  },
  form: true,
  viewBannerSx: {
    root: {
      mt: '-5rem',
      '& h1': {
        fontSize: { xs: '3.625rem', md: '4.375rem' },
        maxWidth: { md: '90%' },
      },
      '& .text': {
        color: colors.white,
        maxWidth: { xs: '100%', md: '70%' },
        fontSize: '1.313rem',
      },
    },
    firstContainer: {
      mt: { sm: '6rem', xs: '2rem' },
      display: { xs: 'flex' },
      flexDirection: { xs: 'column' },
      alignItems: { xs: 'center', md: 'start' },
    },
    secondContainer: {
      display: 'flex',
      justifyContent: { xs: 'center', md: 'end' },
      alignItems: 'center',
      mt: { sx: '0', md: '6rem' },
    },
    title: { color: colors.white },
  },
});

const bannerProps = (t: TFunction<'translation', undefined>): BannerProps => ({
  imageContainerContent: (
    <Box sx={imageContainerStyle}>
      <Box component="img" src={ImageBanner} alt="dos mujeres mirando pantalla de pago" sx={bannerImgStyle} />
      <Box component="a" href="#viewBanner" sx={buttonToFormStyle} className="plexo-button primary-button">
        {t('home.contact.btn-primary')}
      </Box>
    </Box>
  ),
  title: 'home-benefits-title',
  listItems: [
    'home-benefits-item-1',
    'home-benefits-item-2',
    'home-benefits-item-3',
    'home-benefits-item-5',
    'home-benefits-item-6',
  ],
  formButton: '#viewBanner',
  id: 'banner',
  alignTextLeftMobile: true,
  bannerSx: {
    wrapper: {
      mt: { xs: '8rem', sm: '15rem', md: '12rem' },
      mb: { xs: '8rem', sm: '3.5rem' },
      '& h2': { fontSize: { xs: '48px' } },
    },
  },
});

function GatewayAd() {
  const { t } = useTranslation();
  const location: Location = useLocation();
  const isAd: boolean = location.pathname.includes('ads');

  useLayoutEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    if (!html.classList.contains('ads')) html.classList.add('ads');
  }, []);

  useEffect(() => {
    localStorage.removeItem('localLanguage');
    if (isAd) i18n.changeLanguage('es');
  }, [isAd]);

  return (
    <Box className="ads">
      <ViewBanner {...viewBannerProps()} />
      <Marquee />
      <Banner {...bannerProps(t)} gridDirection="row-reverse" />
    </Box>
  );
}

export default GatewayAd;
