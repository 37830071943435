import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UseCase } from '../../types/products.types';
import UseCaseAccordion from './UseCaseAccordion';

function UseCasesMobile() {
  const { t } = useTranslation();
  const useCases: UseCase[] = t('px-links.use-cases.items', { returnObjects: true });

  return (
    <Box className="section">
      {useCases.map((useCase: UseCase) => (
        <UseCaseAccordion useCase={useCase} key={useCase.title} />
      ))}
    </Box>
  );
}

export default UseCasesMobile;
