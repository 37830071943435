import colors from '../../../data/colors';
import { SvgIcon } from '../../../types/svgIcon.types';

const CloudDownload = ({ color }: SvgIcon) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="29.113" height="20.379" viewBox="0 0 29.113 20.379">
    <path
      id="cloud-download"
      d="M26.006,41.407a5.1,5.1,0,0,0-4.9-6.5,5.039,5.039,0,0,0-2.184.491A8.005,8.005,0,0,0,4.376,39.651,6.55,6.55,0,0,0,6.551,52.379h16.74a5.822,5.822,0,0,0,2.716-10.972Zm-2.716,9.516H6.551a5.1,5.1,0,0,1-.682-10.144,6.246,6.246,0,0,1-.045-.773,6.552,6.552,0,0,1,12.619-2.475,3.639,3.639,0,0,1,5.536,4.713,4.367,4.367,0,0,1-.687,8.679Zm-4.858-8.8a.546.546,0,0,0-.773,0l-3.1,3.1V38.369a.547.547,0,0,0-.546-.546h-.364a.547.547,0,0,0-.546.546v6.855l-3.1-3.1a.546.546,0,0,0-.773,0l-.259.259a.546.546,0,0,0,0,.773l4.472,4.472a.546.546,0,0,0,.773,0l4.472-4.472a.546.546,0,0,0,0-.773Z"
      transform="translate(0 -32)"
      fill={color || colors.blue}
    />
  </svg>
);

export default CloudDownload;
