import { Box, SxProps } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

interface ListItemsProps {
  texts: string[];
  color?: 'primary' | 'secondary';
  sx?: SxProps;
}

function ListItems({ texts, color, sx }: ListItemsProps) {
  const { t } = useTranslation();

  return (
    <Box component="ul" className="list-item" sx={sx}>
      {texts.map((text: string) => (
        <li className={clsx(color === 'secondary' && 'text-secondary')} key={text}>
          {t(text)}
        </li>
      ))}
    </Box>
  );
}

export default ListItems;
