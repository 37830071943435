import { Box, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import { TFunction, useTranslation } from 'react-i18next';
import ImageHeroBannerDesktop from '../../assets/img/img-banner-links@2x-desktop.png';
import ImageHeroBannerMobile from '../../assets/img/img-banner-links@2x-mobile.png';
import ImageBanner1Desktop from '../../assets/img/img-links-1@2x-desktop.png';
import ImageBanner1Mobile from '../../assets/img/img-links-1@2x-mobile.png';
import ImageBanner2Desktop from '../../assets/img/img-links-3@2x-desktop.png';
import ImageBanner2Mobile from '../../assets/img/img-links-3@2x-mobile.png';
import ImageCard1 from '../../assets/img/img-links-b2b@2x-desktop.png';
import ImageCard4 from '../../assets/img/img-links-chat@2x-desktop.png';
import ImageCard3 from '../../assets/img/img-links-qr@2x-desktop.png';
import ImageCard2 from '../../assets/img/img-links-rrss@2x-desktop.png';
import UseCasesSection from '../../components/UseCases/UseCasesSection';
import Banner, { BannerProps } from '../../components/common/Banner';
import CardsSection, { CardsSectionProps } from '../../components/common/CardsSection';
import Seo from '../../components/common/Seo';
import VerticalSteps, { VerticalStepsProps } from '../../components/common/VerticalSteps';
import { ViewBannerProps } from '../../components/common/ViewBanner';
import ProductLayout from '../../components/products/ProductLayout';
import { PlexoUrl } from '../../data/urls';
import { Card } from '../../types/card.types';

const viewBannerImage: SxProps = {
  width: { xs: '270%' },
  mt: { xs: '-16rem', md: '-10rem' },
  mb: { xs: '-10rem', md: '-26rem' },
  position: 'relative',
  left: { md: '8rem' },
};

const banner1ImgStyle: SxProps = {
  my: { xs: '-10rem', sm: '-16rem', md: '-8rem' },
  position: 'relative',
  top: { md: '5rem' },
  left: { md: '-5rem' },
  width: { xs: '240%', sm: '190%' },
};

const banner2ImgStyle: SxProps = {
  mt: { xs: '-9rem' },
  mb: { xs: '-7rem' },
  position: 'relative',
  top: { md: '8rem', lg: '4rem' },
  left: { md: '-5rem' },
  width: { xs: '200%', sm: '100%', md: 'auto', lg: '150%' },
  height: { md: '100%', lg: 'auto' },
};

const plexoLinks: string = 'px-links';
const linksStepper: string = `${plexoLinks}.stepper`;
const viewBanner: string = `${plexoLinks}.viewbanner`;
const banner1: string = `${plexoLinks}.banner-1`;
const cards: string = `${plexoLinks}.cards`;
const cardHyphen: string = `${cards}.card-`;
const banner2: string = `${plexoLinks}.banner-2`;
const banner2Hyphen: string = `${banner2}.list-items.item-`;
const contact: string = `${plexoLinks}.contact`;

const viewBannerProps = (isMobile: boolean): ViewBannerProps => ({
  breadcrumbs: { goToProducts: true, current: 'Plexo Links' },
  title: `${viewBanner}.title`,
  paragraph: `${viewBanner}.text`,
  buttonPrimary: { to: '/contacto', content: `${viewBanner}.button-primary` },
  buttonSecondary: { to: PlexoUrl.PX_LINKS, content: `${viewBanner}.button-secondary` },
  arrowIconTo: '#banner-1',
  image: {
    src: isMobile ? ImageHeroBannerMobile : ImageHeroBannerDesktop,
    alt: `${viewBanner}.img-alt`,
    sx: viewBannerImage,
  },
  smallTitle: true,
  viewBannerSx: {
    root: { mb: { xs: '2.5rem', md: '6rem' } },
    secondContainer: { display: 'flex', justifyContent: 'center' },
    title: { maxWidth: { md: '90%' } },
  },
});

const banner1Props = (isMobile: boolean, t: TFunction<'translation', undefined>): BannerProps => ({
  title: `${banner1}.title`,
  imageContainerContent: (
    <Box
      component="img"
      src={isMobile ? ImageBanner1Mobile : ImageBanner1Desktop}
      sx={banner1ImgStyle}
      alt={t(`${viewBanner}.img-alt`)}
      loading="lazy"
    />
  ),
  text: `${banner1}.text`,
  button: { to: '/contacto', content: 'button-start', sx: { mt: { xs: '1rem' } } },
  id: 'banner-1',
});

const cardList: Card[] = [
  { card: `${cardHyphen}1`, image: ImageCard1 },
  { card: `${cardHyphen}2`, image: ImageCard2 },
  { card: `${cardHyphen}3`, image: ImageCard3 },
  { card: `${cardHyphen}4`, image: ImageCard4 },
].map(({ card, image }) => {
  return {
    title: `${card}.title`,
    image,
    text: `${card}.text`,
    altText: `${card}.img-alt`,
  };
});

const cardsSectionProps: CardsSectionProps = {
  title: `${cards}.title`,
  cards: cardList,
  cardSectionSx: {
    root: { mt: { xs: '2rem', md: '8rem' } },
    title: { width: { xs: '80%', lg: '50%' } },
    cardsContainer: { pb: { lg: '5rem' } },
  },
};

const verticalStepProps: VerticalStepsProps = {
  title: `${linksStepper}.title`,
  stepLabels: [
    `${linksStepper}.first`,
    `${linksStepper}.second`,
    `${linksStepper}.third`,
    `${linksStepper}.fourth`,
  ],
  verticalStepsSx: { root: { mt: { xs: '3rem', md: '8rem' }, mb: { xs: '6rem', md: '9.25rem' } } },
};

const banner2Props = (isMobile: boolean, t: TFunction<'translation', undefined>): BannerProps => ({
  title: `${banner2}.title`,
  imageContainerContent: (
    <Box
      component="img"
      src={isMobile ? ImageBanner2Mobile : ImageBanner2Desktop}
      sx={banner2ImgStyle}
      alt={t(`${banner2}.img-alt`)}
      loading="lazy"
    />
  ),
  listItems: [
    `${banner2Hyphen}1`,
    `${banner2Hyphen}2`,
    `${banner2Hyphen}3`,
    `${banner2Hyphen}4`,
    `${banner2Hyphen}5`,
    `${banner2Hyphen}6`,
  ],
  button: { to: '/contacto', content: 'button-start', alignLeftMobile: true, sx: { mt: { md: '1.25rem' } } },
  titleAboveImage: true,
  gridDirection: 'row-reverse',
  bannerSx: {
    wrapper: { mb: { xs: '6rem', md: '17.5rem' } },
    imageContainer: { height: { md: '600px' } },
  },
});

const PlexoLinks = () => {
  const { t } = useTranslation();
  const theme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Seo meta={`${plexoLinks}.meta`} />
      <ProductLayout
        viewBannerProps={viewBannerProps(isMobile)}
        reverseBannerProps={banner1Props(isMobile, t)}
        contactProps={{ title: `${contact}.title` }}
      >
        <CardsSection {...cardsSectionProps} />
        <VerticalSteps {...verticalStepProps} />
        <Banner {...banner2Props(isMobile, t)} />
        <UseCasesSection />
      </ProductLayout>
    </>
  );
};

export default PlexoLinks;
