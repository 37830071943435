import { Box, Grid, Theme, useMediaQuery, useTheme } from '@mui/material';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import WomanBanner from '../../../assets/img/banner2@2x.png';
import WomanBannerMobile from '../../../assets/img/img-banner-home-mobile@2x.png';
//Add in V2
// import LockIcon from '../../../assets/img/lock@2x.png';
// import CardIcon from '../../../assets/img/icon-card@2x.png';
// import RocketIcon from '../../../assets/img/rocket@2x.png';
import { ReactComponent as ArrowIcon } from '../../../assets/svg/complete-arrow.svg';

function Banner() {
  const theme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();

  return (
    <Box id="banner" className="section" sx={{ mt: { xs: '2rem', md: '-5rem' } }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7} className="text-container">
          <h1 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('home-banner-title')) }} />
          <p
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('home-banner-text')) }}
            className="section-text"
          />
          <a href="#payment-methods-anchor" className="button-container">
            <ArrowIcon />
          </a>
        </Grid>
        <Grid item xs={12} md={5} className="img-container">
          <img className="img" src={WomanBanner} alt="Plexo woman banner" />
          <img
            className="mobile-img"
            src={isMobile ? WomanBannerMobile : WomanBanner}
            alt="Plexo woman banner"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Banner;
