import { config } from '../config/config';
import { ContactFormValues } from '../screens/Contact';
import axios from './axios';

interface MailServiceProps extends ContactFormValues {
  captcha: string;
  subject: string;
}

async function mailsService({ fullname, email, message, subject, captcha }: MailServiceProps) {
  try {
    await axios.post(config.backendUrl!, {
      fromName: fullname,
      fromEmail: email,
      body: message,
      subject,
      replyTo: email,
      captcha,
    });

    return true;
  } catch (err) {
    return false;
  }
}

export default mailsService;
