import { SxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Plugins, { PluginsProps } from '../components/Plugins';
import CardsSection, { CardsSectionProps } from '../components/common/CardsSection';
import Seo from '../components/common/Seo';
import ViewBanner from '../components/common/ViewBanner';
import { plugins } from '../data/plugins';
import { plexoProducts } from '../data/products';
import { PlexoEmail, PlexoUrl } from '../data/urls';
import { Card } from '../types/card.types';
import { Product } from '../types/products.types';

const resources: string = 'resources';
const viewbanner: string = `${resources}.viewbanner`;
const cardsSection: string = `${resources}.cards`;
const pluginsSection: string = `${resources}.plugins`;

const cards: Card[] = [plexoProducts[0], plexoProducts[1], plexoProducts[2], plexoProducts[5]].map(
  (product: Product) => {
    const isInvoice: boolean = Boolean(product.moduleTitle?.toLowerCase().includes('invoice'));
    const isEcommerce: boolean = Boolean(product.title?.toLowerCase().includes('e-commerce'));
    const buttonTextKey = isInvoice ? 'button-request' : isEcommerce ? 'button-href' : 'button';

    return {
      title: product.moduleTitle || product.shortTitle,
      image: product.img,
      altText: '',
      button: {
        content: `${cardsSection}.${buttonTextKey}`,
        href: isInvoice
          ? '/contacto'
          : isEcommerce
          ? PlexoUrl.PX_GATEWAY_DOCUMENTATION
          : product.attachment?.path || '',
        download: product.attachment?.filename,
        navLink: isInvoice,
        externalLink: isEcommerce,
      },
    };
  },
);

const cardSectionProps: CardsSectionProps = {
  title: `${cardsSection}.title`,
  cards,
  overlay: 'all',
  cardSectionSx: {
    root: { py: '3rem', pt: { md: '9rem' } },
    title: { textAlign: 'left' },
  },
};

const pluginsProps: PluginsProps = {
  title: `${pluginsSection}.title`,
  plugins,
  pluginsSx: { root: { mt: '3rem', mb: { xs: '3rem', md: '16rem' } } },
};

const textStyle: SxProps = {
  maxWidth: { md: '90%' },
  fontSize: { md: '1.375rem' },
  '& a': { fontSize: { md: '1.375rem' } },
};

const Resources = () => {
  const { t } = useTranslation();

  return (
    <>
      <Seo meta={`${resources}.meta`} />
      <ViewBanner
        breadcrumbs={{ goToProducts: false, current: `${viewbanner}.breadcrumb-current` }}
        title={`${viewbanner}.title`}
        stretchFirstContainer
        smallTitle
        viewBannerSx={{ title: { maxWidth: { md: '80%' } } }}
      >
        <Typography variant="body1" color="secondary.main" sx={textStyle}>
          {t(`${viewbanner}.text`)}&nbsp;
          <Typography component="a" href={`mailto:${PlexoEmail.BUSINESS}`} color="primary.main">
            {PlexoEmail.BUSINESS}
          </Typography>
        </Typography>
      </ViewBanner>
      <CardsSection {...cardSectionProps} />
      <Plugins {...pluginsProps} />
    </>
  );
};

export default Resources;
