import { Box, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import { TFunction, useTranslation } from 'react-i18next';
import ImageBanner2Mobile from '../assets/img/img-alianza-xs@2x.png';
import ImageHeroBannerDesktop from '../assets/img/img-banner-about@2x.png';
import ImageBanner1Desktop from '../assets/img/img-empresa-1@2x.png';
import ImageBanner2Desktop from '../assets/img/img-empresa-2@2x.png';
import ImageBanner3Mobile from '../assets/img/img-empresa-3-mobile@2x.png';
import ImageBanner3Desktop from '../assets/img/img-empresa-3@2x.png';
import ImageHeroBannerMobile from '../assets/img/img-empresa-banner-xs@2x.png';
import ImageBanner1Mobile from '../assets/img/img-trayectoria-xs@2x.png';
import Banner, { BannerProps } from '../components/common/Banner';
import { ContactProps } from '../components/common/Contact';
import Seo from '../components/common/Seo';
import { ViewBannerProps } from '../components/common/ViewBanner';
import ProductLayout from '../components/products/ProductLayout';

const about: string = 'about';
const viewBanner: string = `${about}.viewbanner`;
const banner1: string = `${about}.banner-1`;
const banner2: string = `${about}.banner-2`;
const banner3: string = `${about}.banner-3`;
const contact: string = `${about}.contact`;

const viewBannerImage: SxProps = {
  width: { xs: '230%', md: '250%' },
  marginTop: { xs: '-10rem', md: '0' },
  marginBottom: { xs: '2rem', md: '-32rem' },
  position: { xs: 'unset', md: 'relative' },
  top: { md: '-11rem' },
  left: { md: '8rem' },
};

const viewBannerProps = (isMobile: boolean): ViewBannerProps => ({
  breadcrumbs: { goToProducts: false, current: `${viewBanner}.breadcrumb-current` },
  title: `${viewBanner}.title`,
  paragraph: `${viewBanner}.text-1`,
  paragraphSecondary: `${viewBanner}.text-2`,
  arrowIconTo: '#banner-1',
  image: {
    src: isMobile ? ImageHeroBannerMobile : ImageHeroBannerDesktop,
    alt: `${viewBanner}.img-alt`,
    sx: viewBannerImage,
  },
  viewBannerSx: {
    secondContainer: { display: 'flex', justifyContent: 'center' },
  },
});

const layoutBannerImgStyle: SxProps = {
  width: { xs: '215%', md: '150%' },
  marginLeft: { xs: '2rem', md: '-25rem' },
  marginTop: { xs: '-10rem', md: '0' },
  marginBottom: { xs: '-4rem', md: '0' },
};

const layoutReverseBannerProps = (
  isMobile: boolean,
  t: TFunction<'translation', undefined>,
): BannerProps => ({
  title: `${banner1}.title`,
  imageContainerContent: (
    <Box
      component="img"
      src={isMobile ? ImageBanner1Mobile : ImageBanner1Desktop}
      sx={layoutBannerImgStyle}
      alt={t(`${viewBanner}.img-alt`)}
    />
  ),
  text: `${banner1}.text-1`,
  textSecondary: `${banner1}.text-2`,
  bannerSx: { wrapper: { mb: { md: '-12rem' } } },
  id: 'banner-1',
  alignTextLeftMobile: true,
});

const banner2ImgStyle: SxProps = {
  width: { xs: '215%', md: '150%' },
  marginTop: { xs: '-5rem', md: '0' },
  marginBottom: { xs: '-4rem', md: '0' },
  marginLeft: { xs: '-3rem', md: '6.5rem' },
};

const banner2props = (isMobile: boolean, t: TFunction<'translation', undefined>): BannerProps => ({
  title: `${banner2}.title`,
  text: `${banner2}.text`,
  button: {
    to: '/#products',
    content: `${banner2}.button`,
    sx: { mt: { xs: '25px' } },
    alignLeftMobile: true,
  },
  imageContainerContent: (
    <Box
      component="img"
      src={isMobile ? ImageBanner2Mobile : ImageBanner2Desktop}
      sx={banner2ImgStyle}
      alt={t(`${banner2}.img-alt`)}
    />
  ),
  bannerSx: { wrapper: { mb: { md: '-12rem' } } },
  alignTextLeftMobile: true,
});

const banner3ImgStyle: SxProps = {
  width: { xs: '215%', md: '130%' },
  marginTop: { xs: '-10rem', md: '0' },
  marginBottom: { xs: '-2rem', md: '0' },
  marginLeft: { xs: '0', md: '-10rem' },
};

const banner3props = (isMobile: boolean, t: TFunction<'translation', undefined>): BannerProps => ({
  title: `${banner3}.title`,
  text: `${banner3}.text-1`,
  textSecondary: `${banner3}.text-2`,
  gridDirection: 'row-reverse',
  imageContainerContent: (
    <Box
      component="img"
      src={isMobile ? ImageBanner3Mobile : ImageBanner3Desktop}
      sx={banner3ImgStyle}
      alt={t(`${banner3}.img-alt`)}
    />
  ),
  bannerSx: { wrapper: { mb: { md: '4rem' } } },
  alignTextLeftMobile: true,
});

const contactProps: ContactProps = {
  title: `${contact}.title`,
};

const About = () => {
  const theme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();

  return (
    <>
      <Seo meta={`${about}.meta`} />
      <ProductLayout
        viewBannerProps={viewBannerProps(isMobile)}
        reverseBannerProps={layoutReverseBannerProps(isMobile, t)}
        contactProps={contactProps}
        renderSlider={false}
      >
        <Banner {...banner2props(isMobile, t)} />
        <Banner {...banner3props(isMobile, t)} />
      </ProductLayout>
    </>
  );
};

export default About;
