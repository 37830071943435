import { Grid, GridSize, GridSpacing, SxProps, Tooltip } from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';
import { useTranslation } from 'react-i18next';
import RadialGradient from '../../assets/img/radial-gradient-1.png';
import { Logo } from '../../types/logos.types';

interface LogoGridProps {
  logos: Logo[];
  colSpacing?: ResponsiveStyleValue<GridSpacing>;
  rowSpacing?: ResponsiveStyleValue<GridSpacing>;
  itemMd?: GridSize;
  sx?: LogoGridSx;
}

interface LogoGridSx {
  root?: SxProps;
  item?: SxProps;
}

const gridStyle: SxProps = {
  backgroundImage: `url(${RadialGradient})`,
  backgroundSize: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  display: { xs: 'none', md: 'flex' },
  alignItems: 'center',
  '& img': { width: '76px', height: '76px', borderRadius: '50%' },
};

const LogoGrid = ({ logos, colSpacing, rowSpacing, itemMd, sx }: LogoGridProps) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      columnSpacing={colSpacing || 3}
      rowSpacing={rowSpacing || 5}
      sx={{ ...gridStyle, ...sx?.root }}
    >
      {logos.map((logo: Logo) => {
        const translatedTitle: string = t(logo.title);

        return (
          <Grid item md={itemMd || 4} key={logo.title} sx={sx?.item}>
            <Tooltip title={translatedTitle}>
              <img src={logo.img} alt={translatedTitle} />
            </Tooltip>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default LogoGrid;
