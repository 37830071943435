import { Box, SxProps, Theme } from '@mui/material';

const tabsContainerStyle: SxProps<Theme> = {
  '> div:first-of-type': {
    '> div:first-of-type': {
      borderTopColor: 'transparent',
    },
  },
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={tabsContainerStyle}>{children}</Box>}
    </div>
  );
}

export default TabPanel;
