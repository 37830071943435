import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckIcon from '../../../assets/svg/noun-check-4728432.svg';

function Marquee() {
  const { t } = useTranslation();

  return (
    <Box id="marquee">
      <Box className="texts-container">
        {[
          'marquee-text-1',
          'marquee-text-2',
          'marquee-text-3',
          'marquee-text-4',
          'marquee-text-5',
          'marquee-text-6',
          'marquee-text-1',
          'marquee-text-2',
          'marquee-text-3',
        ].map((marqueeText: string, index: number) => (
          <Box className="text" key={`${marqueeText}_${index}`}>
            <span>
              <img src={CheckIcon} alt="Check icon" />
            </span>
            <span>{t(marqueeText)}</span>
          </Box>
        ))}
      </Box>
      {/* Is necesary to have two marquees to have a continuous animation */}
      <Box className="texts-container">
        {[
          'marquee-text-1',
          'marquee-text-2',
          'marquee-text-3',
          'marquee-text-4',
          'marquee-text-5',
          'marquee-text-6',
          'marquee-text-1',
          'marquee-text-2',
          'marquee-text-3',
        ].map((marqueeText: string, index: number) => (
          <Box className="text" key={`${marqueeText}_${index}`}>
            <span>
              <img src={CheckIcon} alt="Check icon" />
            </span>
            <span>{t(marqueeText)}</span>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default Marquee;
