import { Alert, Slide, Snackbar, SxProps } from '@mui/material';

const snackbarStyles: SxProps = { width: { sm: 'min(80vw, 1200px)' } };

interface CustomSnackbarProps {
  open: boolean;
  message: string;
  handleClose(): void;
  variant: 'success' | 'error' | 'warning';
}

const CustomSnackbar = ({ open, message, handleClose, variant }: CustomSnackbarProps) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      resumeHideDuration={500}
      TransitionComponent={Slide}
      transitionDuration={500}
      onClose={handleClose}
      sx={snackbarStyles}
      style={{ top: '6.5rem' }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert severity={variant} onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
