import { Box, SxProps, Theme, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import colors from '../../data/colors';

interface SuccessCaseProps {
  logo: JSX.Element;
  title: string;
  text1: string;
  text2: string;
  linkText: string;
  linkUrl: string;
}

const container: SxProps<Theme> = {
  backgroundColor: colors.white,
  padding: { xs: '2rem', md: '4rem 5rem' },
  borderRadius: '15px',
  marginBottom: { xs: '5rem', md: '10rem' },
  position: 'relative',
  zIndex: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
};

const overlayStyle: SxProps = {
  background: `transparent radial-gradient(closest-side at 66% 59%, ${colors.teal.regular} 0%, ${colors.skyblue.dark} 66%, ${colors.skyblue.regular} 100%) 0% 0% no-repeat padding-box`,
  opacity: 0.14,
  filter: 'blur(50px)',
  position: 'absolute',
  inset: 0,
};

const titleStyle: SxProps<Theme> = { mb: '3.5rem', textAlign: 'center' };

function SuccessCase({ logo, title, text1, text2, linkText, linkUrl }: SuccessCaseProps) {
  const { t } = useTranslation();

  return (
    <Box component="section" position="relative">
      <Box sx={overlayStyle} />
      <Box className="section" sx={{ width: { xs: '80%', md: '65%' } }}>
        <Typography
          variant="h2"
          className="heading-primary"
          sx={titleStyle}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('success-case-section-title')) }}
        />
        <Box sx={container}>
          {logo}
          {title && (
            <Typography
              variant="h3"
              className="heading-primary"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t(title)) }}
            />
          )}
          {text1 && (
            <Typography
              variant="body1"
              className="text"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t(text1)) }}
            />
          )}
          <Box>
            {text2 && (
              <Typography
                component="span"
                className="heading-primary"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t(text2)) }}
              />
            )}
            {linkText && (
              <a href={linkUrl} target="_blank" rel="noreferrer" className="link">
                {t(linkText)}
              </a>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SuccessCase;
