import { Box, SxProps, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import TinySlider, { TinySliderSettings } from 'tiny-slider-react';
import colors from '../../data/colors';
import { plexoProducts } from '../../data/products';
import { Product } from '../../types/products.types';
import ProductCard from './ProductCard';

const productSlider: SxProps<Theme> = {
  backgroundColor: colors.teal.lightest,
  padding: '100px 0',
  h2: {
    maxWidth: { xs: '80%', md: '55%', xl: '43%' },
    paddingLeft: '10%',
    textAlign: 'left',
  },
};

const sliderContainer: SxProps<Theme> = { padding: '50px 0 0 10%' };

const settings: TinySliderSettings = {
  nav: false,
  controls: false,
  autoplay: true,
  autoplayDirection: 'backward',
  autoplayButtonOutput: false,
  responsive: {
    250: { items: 1.2 },
    500: { items: 1.7 },
    700: { items: 2.5 },
    965: { items: 3.2 },
    1100: { items: 3.5 },
    1300: { items: 4.4 },
    1500: { items: 5.2 },
  },
};

function ProductsSlider() {
  const { t } = useTranslation();
  const location = useLocation();
  const filteredProducts: Product[] = plexoProducts.filter(
    (product: Product) => !location.pathname.includes(product.url) && !product.shortTitle.includes('invoice'),
  );

  return (
    <Box sx={productSlider}>
      <Typography variant="h2">{t('products-slider.title')}</Typography>
      <Box sx={sliderContainer}>
        <TinySlider settings={settings}>
          {filteredProducts.map((product: Product) => (
            <ProductCard product={product} key={product.title} />
          ))}
        </TinySlider>
      </Box>
    </Box>
  );
}

export default ProductsSlider;
