import { Box, SxProps, Theme, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import UseCaseParagraph from './UseCaseParagraph';

const textContainerStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
  justifyContent: 'center',
  marginTop: { xs: '1.5rem', md: 'unset' },
};

interface UseCasesContentProps {
  imageFile: string;
  imageDescription: string;
  needText: string;
  solutionText: string;
  solutionItems?: string[];
}

function UseCasesContent({
  imageFile,
  imageDescription,
  needText,
  solutionText,
  solutionItems,
}: UseCasesContentProps) {
  const theme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('md'));

  const plexoLinks: string = 'px-links';
  const useCasesSectionTexts: string = `${plexoLinks}.use-cases`;

  return (
    <>
      <img
        src={imageFile}
        alt={imageDescription}
        width={isMobile ? '80%' : '40%'}
        height={isMobile ? '80%' : '40%'}
      />
      <Box sx={textContainerStyles}>
        <UseCaseParagraph subtitle={`${useCasesSectionTexts}.need-subtitle`} text={needText} />
        <UseCaseParagraph
          subtitle={`${useCasesSectionTexts}.solution-subtitle`}
          text={solutionText}
          items={solutionItems}
        />
      </Box>
    </>
  );
}

export default UseCasesContent;
