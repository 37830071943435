import { Box, Grid } from '@mui/material';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import TinySlider from 'tiny-slider-react';
import { paymentMethodsLogos } from '../../../data/logos';
import { Logo } from '../../../types/logos.types';
import ListItems from '../../common/ListItems/ListItems';
import CustomLogoGrid from '../../products/CustomLogoGrid';

function PaymentMethods() {
  const { t } = useTranslation();

  const settings = {
    nav: false,
    items: 8,
    gutter: 16,
    controls: false,
    autoplay: true,
    autoplayButtonOutput: false,
  };

  return (
    <>
      <Box id="payment-methods" className="section">
        <span id="payment-methods-anchor" />
        <Grid container columnSpacing={12} className="section-container">
          <Grid item xs={12} md={5} className="img-container">
            <CustomLogoGrid
              logos={paymentMethodsLogos}
              itemsPerRow={[4, 3, 4]}
              colSpacing={{ xs: 1.25, lg: 4 }}
              rowSpacing={3}
              linkTo={{ pathname: '/productos/pasarela', hash: '#banner-2' }}
            />
          </Grid>
          <Grid item xs={12} md={7} className="text-container">
            <h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('home-payment-methods-title')) }} />
            <p className="section-text">{t('home-payment-methods-text')}</p>
            <ListItems
              texts={[
                'home-payment-methods-item-1',
                'home-payment-methods-item-2',
                'home-payment-methods-item-3',
                'home-payment-methods-item-4',
              ]}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="img-container-mobile">
        <Box className="slider-container-mobile">
          <TinySlider settings={settings}>
            {paymentMethodsLogos.map((logo: Logo) => (
              <img src={logo.img} alt={logo.title} key={logo.title} />
            ))}
          </TinySlider>
        </Box>
        <Box className="mobile-button">
          <Link className="plexo-button primary-button" to="/productos/pasarela">
            {t('payment-methods-button')} !
          </Link>
        </Box>
      </Box>
    </>
  );
}

export default PaymentMethods;
