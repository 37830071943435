import { Box, Button, SxProps } from '@mui/material';
import DOMPurify from 'dompurify';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import colors from '../../data/colors';
import { UseCase } from '../../types/products.types';

const containerStyle: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  gap: '1.6rem',
  justifyContent: 'space-between',
  marginBottom: '4.4rem',
};

const itemStyle: SxProps = {
  fontSize: '0.875rem',
  fontFamily: 'Ubuntu',
  color: colors.teal.lightest,
  backgroundColor: colors.cardBackground,
  height: '4.4rem',
  padding: '0.9rem 2.2rem',
  borderRadius: '0.4rem',
  maxWidth: '14.5rem',
  fontWeight: '700',
  '&:hover': {
    backgroundColor: colors.teal.lightest,
    color: colors.blue,
  },
};

const selectedItemStyle: SxProps = {
  ...itemStyle,
  color: colors.blue,
  backgroundColor: colors.teal.lightest,
};

interface UseCasesNavBarProps {
  setSelectedItemIndex: Dispatch<SetStateAction<number>>;
  selectedItemIndex: number;
}

function UseCasesNavBar({ setSelectedItemIndex, selectedItemIndex }: UseCasesNavBarProps) {
  const { t } = useTranslation();
  const useCases: UseCase[] = t('px-links.use-cases.items', { returnObjects: true });

  const handleItemClick = (itemIndex: number) => {
    setSelectedItemIndex(itemIndex);
  };

  return (
    <Box sx={containerStyle} className="section">
      {useCases.map((useCase: UseCase, index) => (
        <Button
          key={useCase.title}
          sx={useCase.title === useCases[selectedItemIndex]?.title ? selectedItemStyle : itemStyle}
          onClick={() => handleItemClick(index)}
        >
          {DOMPurify.sanitize(useCase.title)}
        </Button>
      ))}
    </Box>
  );
}

export default UseCasesNavBar;
