import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  ClickAwayListener,
  Grow,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  SxProps,
  Theme,
} from '@mui/material';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import colors from '../data/colors';
import { plexoProducts } from '../data/products';
import { Product } from '../types/products.types';

const listItemStyle: SxProps<Theme> = {
  borderRadius: '15px',
  bgcolor: colors.white,
  py: '0',
  '& .MuiButtonBase-root': { bgcolor: colors.white },
  '&:hover': { bgcolor: colors.white },
  '& .MuiTypography-root': {
    fontSize: '1rem',
    fontFamily: 'Ubuntu',
    fontWeight: '600',
  },
};

const listItemTextStyle: SxProps<Theme> = {
  color: colors.blue,
  textTransform: 'capitalize',
  fontSize: '0.875rem',
  fontFamily: 'Ubuntu',
  alignItems: 'flex-start',
  '& img': { width: '60px' },
};

const menuStyle: SxProps<Theme> = {
  width: '80%',
  zIndex: '10',
  '& .MuiPaper-root': {
    borderRadius: '15px',
    px: '20px',
    bgcolor: colors.teal.lightest,
  },
  '& .MuiList-root': { '> li:first-of-type': { borderTopColor: 'transparent' } },
  '& .MuiButtonBase-root': { bgcolor: 'unset' },
};

const menuItemStyle: SxProps<Theme> = {
  borderTop: `1px solid ${colors.skyblue.regular}`,
  color: colors.blue,
  textTransform: 'capitalize',
  fontSize: '1rem',
  fontFamily: 'Ubuntu',
  fontWeight: '600',
  alignItems: 'flex-start',
  padding: '0',
  '& img': { width: '60px' },
  '&.Mui-selected': { background: 'transparent' },
};

const menuItemWrap: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
};

interface FaqsMenuMobileProps {
  tabIndex: number;
  setTabIndex: Dispatch<SetStateAction<number>>;
}

function FaqsMenuMobile({ tabIndex, setTabIndex }: FaqsMenuMobileProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open: boolean = Boolean(anchorEl);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setTabIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => setAnchorEl(null);

  const menuItems = plexoProducts.map((product: Product, index: number) => (
    <Box sx={menuItemWrap}>
      <img src={product.icon} alt={t(product.shortTitle)} />
      {t(product.shortTitle)}
    </Box>
  ));

  return (
    <>
      <List component="nav" aria-label="Device settings" ref={anchorRef}>
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
          sx={listItemStyle}
        >
          <ListItemText primary={menuItems[tabIndex]} sx={listItemTextStyle} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </List>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role="select"
        placement="bottom"
        transition
        disablePortal
        sx={menuStyle}
        modifiers={[{ name: 'flip', enabled: false }]}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
                  {menuItems.map((item: JSX.Element, index: number) => (
                    <MenuItem
                      key={`menu-item-${index}`}
                      selected={index === tabIndex}
                      onClick={(event: React.MouseEvent<HTMLElement>) => handleMenuItemClick(event, index)}
                      sx={menuItemStyle}
                      id="plexo-products-dropdown"
                      aria-labelledby="plexo-products-dropdown"
                    >
                      {item}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default FaqsMenuMobile;
