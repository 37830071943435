import { Box, Button, SxProps, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import colors from '../../data/colors';
import { Card as CardType } from '../../types/card.types';
import FileDownload from './icons/FileDownload';

const cardStyle: SxProps = {
  display: { xs: 'grid', sm: 'flex' },
  flexDirection: 'column',
  gridTemplateColumns: '40% calc(60% - 0.75rem)',
  gap: '0.75rem',
  borderRadius: { xs: '19px', sm: '1000px 1000px 150px 150px' },
  p: '1.25rem',
  pl: { xs: '0.625rem', sm: '1.25rem' },
  bgcolor: 'common.white',
  height: { xs: 'fit-content', sm: '100%' },
};

const titleStyle: SxProps = { color: 'secondary.main', fontSize: { sm: '1.4375rem' } };

const textStyle: SxProps = { fontSize: { sm: '1.125rem' }, lineHeight: { xs: 1.1, sm: 1.4 } };

const imgStyle: SxProps = { width: '100%', maxWidth: { xs: '150px', sm: '100%' } };

const buttonStyle: SxProps = {
  mt: { md: 'auto' },
  display: 'flex',
  gap: { xs: '0.25rem', sm: '0.75rem' },
  px: { xs: '0.5rem', sm: '0.25' },
  py: { sm: '0.375rem' },
  width: { xs: 'fit-content', sm: '100%' },
  mx: 'auto',
  bgcolor: colors.teal.dark,
  borderRadius: '24px',
  borderColor: colors.teal.dark,
  '&:hover': {
    bgcolor: colors.teal.resourcesCard,
    borderColor: colors.teal.resourcesCard,
  },
};

const buttonText: SxProps = { fontSize: { xs: '0.75rem', sm: '1.125rem' } };

const textContainer: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: { sm: 1 },
  gap: { xs: '0.5rem', sm: '1.25rem' },
};

const Card = ({ title, image, text, altText, button }: CardType) => {
  const { t } = useTranslation();

  return (
    <Box sx={cardStyle}>
      <Box component="img" src={image} alt={t(altText)} sx={imgStyle} loading="lazy" />
      <Box sx={[textContainer, !!button && { justifyContent: 'center' }]}>
        <Typography variant="h3" sx={[titleStyle, !!button && { textAlign: 'center' }]}>
          {t(title)}
        </Typography>
        {text && (
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t(text)) }}
            className="text"
            sx={textStyle}
          />
        )}
        {button && (
          <Button
            variant="contained"
            color={button.externalLink ? 'secondary' : 'primary'}
            component={button.navLink ? Link : 'a'}
            href={!button.navLink ? button.href : undefined}
            download={!button.navLink ? true : undefined}
            to={button.navLink ? button.href : undefined}
            target={button.externalLink ? '_blank' : undefined}
            sx={{
              ...buttonStyle,
              ...(button.externalLink && {
                bgcolor: undefined,
                borderColor: undefined,
                '&:hover': undefined,
              }),
            }}
          >
            <Typography component="span" sx={buttonText}>
              {t(button.content)}
            </Typography>
            {!button.navLink && !button.externalLink && <FileDownload />}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Card;
