import { ContactFormAdsValues } from '../components/AdsForm';
import { config } from '../config/config';
import axios from './axios';

interface MailServiceProps extends ContactFormAdsValues {
  captchaToken: string;
  subject: string;
}

async function sendEbookMail({ fullname, company, email, subject, captchaToken }: MailServiceProps) {
  try {
    await axios.post(config.backendUrlEbook!, {
      subject,
      userFullName: fullname,
      userCompany: company,
      userEmail: email,
      captchaToken,
    });

    return true;
  } catch (err) {
    return false;
  }
}

export default sendEbookMail;
