import { ReactNode } from 'react';
import Banner, { BannerProps } from '../common/Banner';
import Contact, { ContactProps } from '../common/Contact';
import ProductsSlider from '../common/ProductsSlider';
import ViewBanner, { ViewBannerProps } from '../common/ViewBanner';

interface ProductLayoutProps {
  viewBannerProps: ViewBannerProps;
  reverseBannerProps: BannerProps;
  contactProps?: ContactProps;
  renderSlider?: boolean;
  children?: ReactNode;
}

const ProductLayout = ({
  viewBannerProps,
  reverseBannerProps,
  contactProps,
  children,
  renderSlider = true,
}: ProductLayoutProps) => {
  return (
    <>
      <ViewBanner {...viewBannerProps} />
      <Banner {...reverseBannerProps} gridDirection="row-reverse" />
      {children}
      {renderSlider && <ProductsSlider />}
      <Contact {...contactProps} />
    </>
  );
};

export default ProductLayout;
